import { DateTime } from 'luxon';

// Memoize the hours arrays for each duration
const memoizedHours = {
    0.5: Array.from({ length: 32 }, (_, i) => {
        const hour = Math.floor(i / 2) + 8;
        const minutes = (i % 2) * 30;
        return DateTime.fromObject({ hour, minute: minutes }).toLocaleString(DateTime.TIME_SIMPLE);
    }),
    1: Array.from({ length: 16 }, (_, i) => {
        const hour = i + 8;
        return DateTime.fromObject({ hour }).toLocaleString(DateTime.TIME_SIMPLE);
    }),
    2: Array.from({ length: 9 }, (_, i) => {
        const hour = i * 2 + 8;
        return DateTime.fromObject({ hour }).toLocaleString(DateTime.TIME_SIMPLE);
    })
};

export const generateWeekDates = (date) => {
    
    const arrayOfDate = [];

    // find the first day of the week
    let firstDayOfWeek = date;
    while (firstDayOfWeek.weekday !== 1) {
        firstDayOfWeek = firstDayOfWeek.minus({ days: 1 });
    }

    arrayOfDate.push({
        date: null,    
        hours: [
            "9am", "", "9:30am", "", "10am", "", "10:30am", "", "11am", "", "11:30am", "",
            "12pm", "", "12:30pm", "", "1pm", "", "1:30pm", "", "2pm", "", "2:30pm", "",
            "3pm", "", "3:30pm", "", "4pm", "", "4:30pm", "", "5pm", "", "5:30pm", "",
            "6pm", "", "6:30pm", "", "7pm", "", "7:30pm", "", "8pm", "", "8:30pm", "",
            "9pm", "", "9:30pm", "", "10pm", "", "10:30pm", "", "11pm", "", "11:30pm", ""
        ]
    });

    for (let i = 0; i < 7; i++) {
        const date = firstDayOfWeek.plus({ days: i });
        date.set({ hour: 9, minute: 0, second: 0 });
        const dateHourMap = [
            "", "", "", "", "", "", "", "", "", "", "", "", 
            "", "", "", "", "", "", "", "", "", "", "", "",
            "", "", "", "", "", "", "", "", "", "", "", "",
            "", "", "", "", "", "", "", "", "", "", "", "",
            "", "", "", "", "", "", "", "", "", "", "", "",
        ];
        arrayOfDate.push(
            {
                date: date,
                hours: dateHourMap
            }
        );
    }

    return arrayOfDate;
};

export const generateWeekDatesForTeacherScheduler = (date, duration) => {
    const arrayOfDate = [];
    let firstDayOfWeek = date;

    while (firstDayOfWeek.weekday !== 1) {
        firstDayOfWeek = firstDayOfWeek.minus({ days: 1 });
    }

    // Use memoized hours array
    const hours = memoizedHours[duration];
    if (!hours) return arrayOfDate;

    // Add the time column
    arrayOfDate.push({
        date: null,
        hours: hours,
    });

    // Pre-calculate the base date settings
    const baseSettings = { hour: 0, minute: 0, second: 0 };

    // Add each day of the week
    for (let i = 0; i < 7; i++) {
        const currentDate = firstDayOfWeek.plus({ days: i }).set(baseSettings);
        arrayOfDate.push({
            date: currentDate,
            hours: hours,
        });
    }

    return arrayOfDate;
};

export const generateWeekDatesForAdmin = (date, duration, startTime, endTime) => {
    const arrayOfDate = [];
    let firstDayOfWeek = date;

    while (firstDayOfWeek.weekday !== 1) {
        firstDayOfWeek = firstDayOfWeek.minus({ days: 1 });
    }
    
    const start = DateTime.fromISO(`${firstDayOfWeek.toISODate()}T${startTime}`);
    const end = DateTime.fromISO(`${firstDayOfWeek.toISODate()}T${endTime}`);
    const numOfIntervals = Math.floor(end.diff(start, 'hours').hours / duration);

    // Pre-generate hours array
    const hours = Array.from({ length: numOfIntervals }, (_, index) => 
        start.plus({ hours: index * duration }).toFormat('h:mm a')
    );

    // Add time column
    arrayOfDate.push({ date: null, hours });

    // Pre-calculate base settings
    const baseSettings = { hour: 0, minute: 0, second: 0 };

    // Add days
    for (let i = 0; i < 7; i++) {
        arrayOfDate.push({
            date: firstDayOfWeek.plus({ days: i }).set(baseSettings),
            hours: hours,
        });
    }

    return arrayOfDate;
};

export const checkifDateIsInThisWeek = (date) => {
    if (date === null) return false;

    const today = DateTime.now();
    let firstDayOfWeek = today;
    while (firstDayOfWeek.weekday !== 1) {
        firstDayOfWeek = firstDayOfWeek.minus({ days: 1 });
    }
    let lastDayOfWeek = firstDayOfWeek.plus({ days: 6 });
    firstDayOfWeek = firstDayOfWeek.set({ hour: 9, minute: 0, second: 0 });
    lastDayOfWeek = lastDayOfWeek.set({ hour: 23, minute: 59, second: 59 });
    return date >= firstDayOfWeek && date <= lastDayOfWeek;
};