import React from 'react';
import dayjs from 'dayjs';
import { Box, Typography, Modal } from '@mui/material';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// Extend dayjs with the required plugins
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const MiniCalendarsModal = ({
    vacations,
    open, 
    onClose, 
    startDate, 
    endDate,
    year,
    miniMonth,
    activeDay,
    calculatorInput,
}) => {

    let weekCount = 0;

    const generateCalendarForMonth = (month) => {
        const startOfMonth = month.startOf('month');
        const endOfMonth = month.endOf('month');
        const daysInMonth = endOfMonth.date();
        const firstDayOfMonth = startOfMonth.day();

        const days = [];

        const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        
        // Add day names (Mon, Tue, etc.)        
        dayNames.forEach((dayName, index) => {
            days.push(
                <Box
                    key={`dayname-${index}`}
                    sx={{
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                    }}
                >
                    {dayName}
                </Box>
            );
        });

        // Previous month's days (blurred style)
        for (let i = 0; i < firstDayOfMonth; i++) {
            const prevMonthDay = startOfMonth.subtract(firstDayOfMonth - i, 'day');
            days.push(
                <Box
                    key={`prev-day-${i}`}
                    sx={{
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'rgba(0, 0, 0, 0.38)',
                    }}
                >
                    {prevMonthDay.date()}
                </Box>
            );
        }

        let isVacationDay = false;

        // Helper function to check if a day is a vacation day
        const isVacation = (currentDay) => {
            return vacations.some(vacation =>
                dayjs(currentDay).isBetween(vacation.from, vacation.to, 'day', '[]')
            );
        };

        // Add actual days
        for (let day = 1; day <= daysInMonth; day++) {
            const currentDay = startOfMonth.date(day);
            const isInRange = currentDay.isBetween(startDate, endDate, 'day', '[]');
            const isStartOrEnd = currentDay.isSame(startDate, 'day') || currentDay.isSame(endDate, 'day')
            
            // Check if the current day is a vacation day
            isVacationDay = isVacation(currentDay);

            // Increment week count on each Monday within the range
            if (currentDay.day() === 1 && currentDay.isSameOrAfter(startDate, 'day') && currentDay.isBefore(endDate, 'day') && !isVacationDay) {
                weekCount++;
            }

            days.push(
                <Box
                    key={`day-${day}`}
                    sx={{
                        position: 'relative',
                        width: '41px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: isVacationDay ? '#ffcccc' : (isInRange ? '#cce6fc' : 'transparent'),
                        borderRadius: isStartOrEnd ? '50%' : 'none',
                        border: isStartOrEnd ? '2px solid #000' : 'none',
                    }}
                >
                    {/* Display the week count above the first day of each week */}
                    {currentDay.day() === 1 && isInRange && !isVacationDay ? (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '-8px',
                                left: '-50px',
                                width: '100%',
                                textAlign: 'center',
                                fontSize: '11px',
                                fontWeight: 'bold',
                            }}
                        >
                            {`${weekCount} 주차`}
                        </Box>
                    ) : (
                        currentDay.day() === 2 && isInRange && isVacationDay && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '-13px',
                                left: '-50px',
                                width: '100%',
                                textAlign: 'center',
                                fontSize: '11px',
                                fontWeight: 'bold',
                            }}
                        >
                            Vacation
                        </Box>
                    )
                )}
                    {day}
                </Box>
            );
        }

        // Calculate how many days are left to fill a full 6-week grid (42 cells)
        const totalCellsSoFar = days.length;
        const remainingCells = 42 - totalCellsSoFar;

        // Next month's days (blurred style)
        for (let j = 1; j <= remainingCells; j++) {
            const nextMonthDay = endOfMonth.add(j, 'day');
            days.push(
                <Box
                    key={`next-${j}`}
                    sx={{
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'rgba(0, 0, 0, 0.38)',
                    }}
                >
                    {nextMonthDay.date()}
                </Box>
            );
        }

        return days;
    };

    // const getMonthsInRange = (startDate, endDate) => {
    //     const months = [];
    //     let currentMonth = startDate.startOf('month');
    
    //     while (currentMonth.isBefore(endDate)) {
    //         months.push(currentMonth);
    //         currentMonth = currentMonth.add(1, 'month');
    //     }
    
    //     return months;
    // };    

    const getMonthsInRange = (startDate, endDate) => {
        const months = [];
        let currentMonth = dayjs(startDate).startOf('month');
        const endMonth = dayjs(endDate).startOf('month');
    
        while (currentMonth.isSameOrBefore(endMonth, 'month')) {
            months.push(currentMonth);
            currentMonth = currentMonth.add(1, 'month');
        }
    
        return months;
    };    

    const months = getMonthsInRange(dayjs(startDate), dayjs(endDate));

    return (
        <Modal open={open} onClose={onClose}>            
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    p: 3,
                    boxShadow: 24,
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2,
                    width: 'auto',
                    height: 'auto',
                    maxWidth: '80%',
                    maxHeight: '80%',
                    overflowY: 'auto',
                }}
            >            
                {months.map((month) => (
                    <Box key={month.format('YYYY-MM')} sx={{ display: 'flex', flexDirection: 'column', border: '1px solid black', p: 1 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="h6" mb={1}>
                                    Picked Date: {miniMonth + 1}/{activeDay}/{year}
                                </Typography>
                                {calculatorInput.event &&
                                    <Typography variant="h6" mb={2}>
                                        {calculatorInput.event.name}: {dayjs(calculatorInput.event.date).format('YYYY/MM/DD')}
                                    </Typography>
                                }
                                {calculatorInput.date &&
                                    <Typography variant="h6" mb={2}>
                                        Selected Date: {calculatorInput.date.format('YYYY/MM/DD')}
                                    </Typography>
                                }
                            </Box>
                           <Box sx={{ display: 'flex', flexDirection: 'column' }}>                        
                                <FiberManualRecordIcon sx={{color: "#ffcccc", ml: 1}} />
                                <Typography variant="caption">Vacation</Typography>
                            </Box>
                        </Box>
                        <Typography variant="h6" align="center" marginBottom={2}>
                            {month.format('MMMM YYYY')}
                        </Typography>
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(7, 40px)',
                                gridTemplateRows: 'repeat(6, 40px)',
                                gap: 1,
                            }}
                        >
                            {generateCalendarForMonth(month)}
                        </Box>
                    </Box>
                ))}
            </Box>
        </Modal>
    );
};

export default MiniCalendarsModal;
