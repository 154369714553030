import React from 'react';
import Button from '@mui/material/Button';
import { Box, IconButton, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CloseIcon from '@mui/icons-material/Close';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AreYouSureModal from '../../../components/AreYouSureModal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const tableBorder = {
    border: '1px solid black',
    borderCollapse: 'collapse',
    padding: '8px',
};

function PreviousMemosModal({
    user_id,
    colors,
    open,
    handleClose,
    socket,
    socketConnected,
    enqueueSnackbar,
    setBackdropOpen,
    handleBackdropClose,
}) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxHeight: '96%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        bgcolor: colors.primary[600],
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [memoType, setMemoType] = React.useState("prevWeeklyMainMemos"); // prevWeeklyMainMemos, prevWeeklyDailyMemos, prevWeeklyCellMemos
    const [prevMemos, setPrevMemos] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [limit, setLimit] = React.useState(30);
    const [totalPages, setTotalPages] = React.useState(1);
    
    const [deleteAllAreYouSureModalOpen, setDeleteAllAreYouSureModalOpen] = React.useState(false);
    // const [areYouSureModalOpen, setAreYouSureModalOpen] = React.useState(false);
    // const [selectedMemo, setSelectedMemo] = React.useState(null);

    //load data from localStorage
    React.useEffect(() => {
        if (!open) return;

        setBackdropOpen(true);

        try {
            const memos = JSON.parse(localStorage.getItem(memoType));
            if (memos && memos.length > 0) {
                setPrevMemos(memos);
                setTotalPages(Math.ceil(memos.length / limit));
                setPage(1);
            } else {
                setPrevMemos([]);
                setTotalPages(1);
                setPage(1);
            }
        } catch (error) {
            console.error(error);
            setPrevMemos([]);
            setTotalPages(1);
            setPage(1);
        }

        setBackdropOpen(false);

    }, [memoType, open]);

    //handle pagination
    React.useEffect(() => {
        setTotalPages(Math.ceil(prevMemos.length / limit));
    }, [limit, prevMemos.length]);

    const handleRestoreMemo = (memo) => {
        if (!memo) return;
        if (!socketConnected) {
            enqueueSnackbar("Socket is not connected!", { variant: "error" });
            return;
        }

        setBackdropOpen(true);

        try {
            if (memoType === "prevWeeklyMainMemos" && memo.id) {
                // emit restore main memo
                socket.emit("update.WeeklyMainMemos", {
                    id: memo.id,
                    memo: memo.memo,
                    is_restored: true,
                });
            } else if (memoType === "prevWeeklyDailyMemos" && memo.id) {
                // emit restore daily memo
                socket.emit("update.WeeklyDailyMemos", {
                    id: memo.id,
                    memo: memo.memo,
                    is_restored: true,
                });
            } else if (memoType === "prevWeeklyCellMemos" && (memo.id || (memo.date && memo.time))) {
                // emit restore cell memo
                socket.emit("update.WeeklyCellMemos", {
                    id: memo.id ? memo.id : null,
                    date: memo.date,
                    time: memo.time,
                    memo: memo.memo,
                    zoom_id: memo.zoom_id ? memo.zoom_id : null,
                    is_restored: true,
                });
            }
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Failed to restore memo!", { variant: "error" });
        }

        setBackdropOpen(false);
        // setAreYouSureModalOpen(false);
        // setSelectedMemo(null);
    };
    
    return (
        <Modal
            aria-labelledby="scheduled-messages-modal"
            aria-describedby="scheduled-messages-modal-description"
            open={open}
            onClose={() => {
                setMemoType("prevWeeklyMainMemos");
                setPrevMemos([]);
                setPage(1);
                setLimit(30);
                setTotalPages(1);
        
                handleClose();
            }}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Typography variant="h4" color={colors.primary[100]} mb={2}>
                        Previous Memos
                    </Typography>
                    <Typography variant="h5" color={colors.redAccent[400]} mb={2}>
                        Memos here are the previous memos that were updated from this device (not from other devices).
                    </Typography>
                    <CloseIcon sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={handleClose} />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            gap: 2,
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                gap: 1,
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setMemoType("prevWeeklyMainMemos")}
                                sx={{
                                    backgroundColor: memoType === "prevWeeklyMainMemos" && colors.greenAccent[500],
                                }}
                            >
                                Main Memos
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setMemoType("prevWeeklyDailyMemos")}
                                sx={{
                                    backgroundColor: memoType === "prevWeeklyDailyMemos" && colors.greenAccent[500],
                                }}
                            >
                                Daily Memos
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setMemoType("prevWeeklyCellMemos")}
                                sx={{
                                    backgroundColor: memoType === "prevWeeklyCellMemos" && colors.greenAccent[500],
                                }}
                            >
                                Cell Memos
                            </Button>
                            <Button
                                variant="outlined"
                                color='error'
                                onClick={() => {
                                    setDeleteAllAreYouSureModalOpen(true);
                                }}
                            >
                                Delete All
                                <DeleteForeverIcon color="error" sx={{ mb: 0.2 }} />
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "end",
                                marginLeft: "auto",
                                gap: 1,
                            }}
                        >
                            <Typography variant="h5" color={colors.primary[450]}>
                                Limit:
                            </Typography>
                            <select
                                value={limit}
                                onChange={(event) => setLimit(Number(event.target.value))}
                                style={{
                                    width: 50,
                                    padding: 5,
                                    borderRadius: 5,
                                    border: `1px solid ${colors.primary[450]}`,
                                }}
                            >
                                <option value={30}>30</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            position: "relative",
                            display: "inline-block",
                            width: "100%",
                            overflowX: "auto",
                        }}
                    >
                        <table 
                            style={{
                                border: '1px solid black',
                                borderCollapse: 'collapse',
                                padding: '8px',
                                minHeight: 400,
                                width: "100%",
                            }}
                        >
                            <thead style={tableBorder}>
                                <tr>
                                    <th 
                                        style={{
                                            border: '1px solid black',
                                            borderCollapse: 'collapse',
                                            padding: '8px',
                                            width: '10%',
                                        }}
                                    >
                                        No.
                                    </th>
                                    <th 
                                        style={{
                                            border: '1px solid black',
                                            borderCollapse: 'collapse',
                                            padding: '8px',
                                            width: '80%',
                                        }}
                                    >
                                        Previous Memo
                                    </th>
                                    <th 
                                        style={{
                                            border: '1px solid black',
                                            borderCollapse: 'collapse',
                                            padding: '8px',
                                            width: '10%',
                                        }}
                                    >
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={tableBorder}>
                                {prevMemos.map((memo, index) => {
                                    if (index < (page - 1) * limit || index >= page * limit) return null;

                                    return (
                                        <tr key={index}>
                                            <td
                                                style={{
                                                    border: '1px solid black',
                                                    borderCollapse: 'collapse',
                                                    padding: '8px',
                                                    width: '10%',
                                                }}
                                            >
                                                {index + 1}
                                            </td>
                                            <td
                                                style={{
                                                    border: '1px solid black',
                                                    borderCollapse: 'collapse',
                                                    padding: '8px',
                                                    width: '80%',
                                                }}
                                            >
                                                {memo.memo}
                                            </td>
                                            <td
                                                style={{
                                                    border: '1px solid black',
                                                    borderCollapse: 'collapse',
                                                    padding: '8px',
                                                    width: '10%',
                                                }}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() => {
                                                        // setSelectedMemo(memo);
                                                        handleRestoreMemo(memo);
                                                    }}
                                                >
                                                    Restore
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 2,
                        }}
                    >
                        <IconButton
                            disabled={page <= 1}
                            onClick={() => setPage(page - 1)}
                        >
                            <ArrowLeftIcon />
                        </IconButton>
                        <Typography variant="h6" color={colors.primary[450]}>
                            Page {page}
                        </Typography>
                        <IconButton
                            disabled={page >= totalPages}
                            onClick={() => setPage(page + 1)}
                        >
                            <ArrowRightIcon />
                        </IconButton>
                    </Box>
                    {/* <AreYouSureModal
                        colors={colors}
                        open={areYouSureModalOpen}
                        setOpen={setAreYouSureModalOpen}
                        title="Restore Memo"
                        message="Are you sure you want to restore this memo?"
                        onConfirm={() => handleRestoreMemo(selectedMemo)}
                    /> */}
                    <AreYouSureModal
                        colors={colors}
                        open={deleteAllAreYouSureModalOpen}
                        setOpen={setDeleteAllAreYouSureModalOpen}
                        title="Delete All Previous Memos"
                        message="Are you sure you want to delete all previous memos? (Those memos will be permanently deleted)"
                        onConfirm={() => {
                            localStorage.removeItem("prevWeeklyMainMemos");
                            localStorage.removeItem("prevWeeklyDailyMemos");
                            localStorage.removeItem("prevWeeklyCellMemos");

                            localStorage.setItem("prevWeeklyMainMemos", JSON.stringify([]));
                            localStorage.setItem("prevWeeklyDailyMemos", JSON.stringify([]));
                            localStorage.setItem("prevWeeklyCellMemos", JSON.stringify([]));

                            setPrevMemos([]);
                            setTotalPages(1);
                            setPage(1);
                            setDeleteAllAreYouSureModalOpen(false);
                        }}
                    />
                </Box>
            </Fade>
        </Modal>
    )
}

export default PreviousMemosModal