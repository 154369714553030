import React from "react";
import { Box, IconButton, Button, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import QuickAdd from "./topBarComp/QuickAdd";
import MemoNotification from "./topBarComp/MemoNotification";
import AccountUpdate from "../classrooms/topBarComp/AccountUpdate";
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import NoteAltIcon from '@mui/icons-material/NoteAlt';

const Topbar = ({ adminRestrictions }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const [notificationRemoved, setNotificationRemoved] = React.useState(false);
    const admin_name = localStorage.getItem("userName");

    // auth
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        localStorage.removeItem("selectedMenu");
        navigate("/signin");
    }

    // reminders
    const [reminders, setReminders] = React.useState([]);
    const loadReminders = async () => {
        await Axios.get(`${process.env.REACT_APP_URL}/api/v1/memos/reminders`, {
            timeout: 10000,
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setReminders(response.data);
        }).catch((error) => {
            if (error.response !== null && error.response.status === 401) {
                alert(error.response.data.error || error.response.data.message);
                handleLogout();
                return;
            } else {
                alert(error.response.data.error || error.response.data.message);
                return;
            }
        });
    }

    React.useEffect(() => {
        loadReminders();
        setNotificationRemoved(false);
        // setInterval(() => {
        //     loadReminders();
        // }, 60000)
    }, [notificationRemoved]);

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {/* SEARCH BAR */}
            <Box
                display="flex"
                backgroundColor={colors.primary[700]}
                borderRadius="3px"
                boxShadow={10}
            >
                {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
                <IconButton type="button" sx={{ p: 1 }}>
                    <SearchIcon />
                </IconButton> */}
            </Box>

            {/* ICONS */}
            <Box display="flex" gap={1}>
                {/* Biweekly Paycheck Calculator for Vice Principal */}
                {admin_name === "Sungha Hong" || admin_name === "Gyuseok Park" || admin_name === "Keunwoo Song" || admin_name === "Brian Lee" ? (
                    <IconButton onClick={() => navigate("/admin/manager-memos")}>
                        <NoteAltIcon />
                    </IconButton>
                ) : null}
                {admin_name === "Amy An" || admin_name === "Gyuseok Park" || admin_name === "Keunwoo Song" || admin_name === "Brian Lee" ? (
                    <>
                        <IconButton onClick={() => navigate("/admin/vice-president-memos")}>
                            <NoteAltIcon />
                        </IconButton>
                        <IconButton onClick={() => navigate("/admin/paycheck-calculator")}>
                            <PaymentsOutlinedIcon />
                        </IconButton>
                    </>
                ) : null}

                {/* Account Update Icon */}
                <AccountUpdate color="default" />

                {/* Quick Add Icon */}
                {adminRestrictions
                    && adminRestrictions.length > 0
                    && adminRestrictions.includes('quick add') ? null : <QuickAdd />
                }

                {/* Color Mode Icon */}
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton>

                {/* Notifications Icon */}
                {adminRestrictions
                    && adminRestrictions.length > 0
                    && adminRestrictions.includes('memo notification') ? null : <MemoNotification reminders={reminders} setNotificationRemoved={setNotificationRemoved} />
                }


                {/* Sign Out Button */}
                <Button
                    fullWidth
                    variant="contained"
                    color='secondary'
                    onClick={handleLogout}
                >
                    <Typography color={colors.primary[400]}>Sign Out</Typography>
                </Button>
            </Box>
        </Box>
    );
};

export default Topbar;