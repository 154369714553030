import socketio from "socket.io-client";
import React from "react";

export const getAuthToken = () => {
    if (!document.cookie) return null;
    
    const tokenCookie = document.cookie
        .split(';')
        .find(cookie => cookie.trim().startsWith('_auth'));
        
    return tokenCookie ? tokenCookie.split('=')[1].trim() : null;
};

export const socket = socketio(process.env.REACT_APP_URL, {
    autoConnect: false,
    extraHeaders: {
        Authorization: `Bearer ${getAuthToken()}`,
    },
});

export const SocketContext = React.createContext();