import * as React from 'react';
import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { motion } from "framer-motion";
import { useTheme, Box, Tooltip } from "@mui/material";
import { tokens } from "../../theme";
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import Axios from 'axios';
import Alert from '../../components/alert/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import MoreInfoModal from '../students/moreInfo/MoreInfoModal';
import AddMemoDatePicker from '../students/moreInfo/AddMemoDatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import PersonOffIcon from '@mui/icons-material/PersonOff';

export default function ConsultationCard({
    student,
    setStudentUpdated,
    savePrevActions,
    prevActions,
    setBeingModified,
    updatePotentialStudentsInfo,
    updateInfoMail,
    updateMemo,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // for alert
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = React.useState("");
    const [errorAlertOpen, setErrorAlertOpen] = React.useState(false);
    const [errorAlertMessage, setErrorAlertMessage] = React.useState("");

    // for dialog
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleClickDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    // more and add memo button toggle
    const [moreMemos, setMoreMemos] = React.useState(false);

    // new memo info
    const [newMemoTitle, setNewMemoTitle] = React.useState("");
    const [newMemoContent, setNewMemoContent] = React.useState("");
    const [newMemoReminder, setNewMemoReminder] = React.useState(null);

    // for modify info
    const [modifyingConsulDate, setModifyingConsulDate] = React.useState(false);
    const [finishingModifyingConsulDate, setFinishingModifyingConsulDate] = React.useState(false);
    const [modifiedConsulDate, setModifiedConsulDate] = React.useState(student.consultation_date ?? "");

    const [modifyingDateEmailSent, setModifyingDateEmailSent] = React.useState(false);
    const [finishingModifyingDateEmailSent, setFinishingModifyingDateEmailSent] = React.useState(false);
    const [modifiedDateEmailSent, setModifiedDateEmailSent] = React.useState(
        student.info_mail !== undefined
            && student.info_mail !== null
            && student.info_mail.date !== null
            && student.info_mail.date !== ""
            ? dayjs(student.info_mail.date)
            : null
    );

    const [modifyingParentName, setModifyingParentName] = React.useState(false);
    const [finishingModifyingParentName, setFinishingModifyingParentName] = React.useState(false);
    const [modifiedParentName, setModifiedParentName] = React.useState(student.parent_name ?? "");

    const [modifyingPhone, setModifyingPhone] = React.useState(false);
    const [finishingModifyingPhone, setFinishingModifyingPhone] = React.useState(false);
    const [modifiedPhone, setModifiedPhone] = React.useState(student.parent_phone ?? "");

    const [modifyingEmail, setModifyingEmail] = React.useState(false);
    const [finishingModifyingEmail, setFinishingModifyingEmail] = React.useState(false);
    const [modifiedEmail, setModifiedEmail] = React.useState(student.parent_email ?? "");

    // for memo, using memo id to modify
    const [modifyingMemo, setModifyingMemo] = React.useState(-1);
    const [finishingModifyingMemo, setFinishingModifyingMemo] = React.useState([-1, false]);
    const [modifiedMemo, setModifiedMemo] = React.useState(
        student.memos ? memoMap(student.memos) : new Map()
    );

    // for modifying is_requested (학부모님이 initial email 이후 다시 연락오셔서 이메일을 원하실때)
    const [modifiedIsRequested, setModifiedIsRequested] = React.useState(
        student.info_mail?.is_requested ?? false
    );

    // for memo title
    const [modifyingMemoTitle, setModifyingMemoTitle] = React.useState(-1);
    const [finishingModifyingMemoTitle, setFinishingModifyingMemoTitle] = React.useState([-1, false]);

    function memoMap(memos) {
        const memoMap = new Map();
        memos.forEach(memo => {
            memoMap.set(memo.id, memo);
        });

        return memoMap;
    }

    function reverseMemos(memos) {
        const reversedMemos = [];
        memos.forEach(memo => {
            reversedMemos.unshift(memo);
        });

        return reversedMemos;
    }

    React.useEffect(() => {
        if (!modifyingConsulDate)
            setModifiedConsulDate(student.consultation_date ?? "");
        if (!modifyingDateEmailSent) {
            setModifiedDateEmailSent(
                student.info_mail !== undefined
                    && student.info_mail !== null
                    && student.info_mail.date !== null
                    && student.info_mail.date !== ""
                    ? dayjs(student.info_mail.date)
                    : null
            );
        } if (!modifyingParentName)
            setModifiedParentName(student.parent_name ?? "");
        if (!modifyingPhone)
            setModifiedPhone(student.parent_phone ?? "");
        if (!modifyingEmail)
            setModifiedEmail(student.parent_email ?? "");
        if (modifyingMemo === -1)
            setModifiedMemo(student.memos ? memoMap(student.memos) : new Map());
    }, [student]);


    React.useEffect(() => {
        setModifiedIsRequested(student.info_mail?.is_requested ?? false);
    }, [student]);


    const uploadNewMemo = async (event) => {
        event.preventDefault();

        if (newMemoTitle === "" && newMemoContent === "") {
            setErrorAlertMessage("Please enter a title or content for the new memo.");
            setErrorAlertOpen(true);
            return;
        }

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/students/${student.uuid}/memos`, {
            title: newMemoTitle,
            content: newMemoContent,
            reminder: newMemoReminder,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("New memo has been added!");
            setSuccessAlertOpen(true);
            setStudentUpdated(true);
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });

        setNewMemoTitle("");
        setNewMemoContent("");
        setNewMemoReminder(null);
    };

    const handleInfoMailUpdate = async (event) => {
        event.preventDefault();

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/students/${student.uuid}/info-mails/${student.info_mail.id}`, {
            is_sent: true,
            is_requested: false
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Info mail status has been updated!");
            setSuccessAlertOpen(true);
            setStudentUpdated(true);
            handleDialogClose();
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    };

    function useOuterClick(callback) {
        const innerRef = React.useRef();
        const callbackRef = React.useRef();

        // set current callback in ref, before second useEffect uses it
        React.useEffect(() => { // useEffect wrapper to be safe for concurrent mode
            callbackRef.current = callback;
        });

        React.useEffect(() => {
            document.addEventListener("click", handleClick);
            return () => document.removeEventListener("click", handleClick);

            // read most recent callback and innerRef dom node from refs
            function handleClick(e) {
                if (
                    innerRef.current &&
                    callbackRef.current &&
                    !innerRef.current.contains(e.target)
                ) {
                    callbackRef.current(e);
                }
            }
        }, []); // no need for callback + innerRef dep

        return innerRef; // return ref; client can omit `useRef`
    }

    const consulDateRef = useOuterClick(e => {
        // if other input is being modified, return
        if (modifyingDateEmailSent || modifyingParentName || modifyingPhone || modifyingEmail || modifyingMemo > -1 || modifyingMemoTitle > -1) return;

        if (!finishingModifyingConsulDate) {
            setFinishingModifyingConsulDate(true);
            consulDateRef.current.focus();
            setBeingModified(true);
            return;
        };

        if (consulDateRef.current.value === student.consultation_date || (consulDateRef.current.value === "" && student.consultation_date === null)) {
            console.log("No change");
            setModifyingConsulDate(false);
            setFinishingModifyingConsulDate(false);
            setBeingModified(false);
            return;
        }

        // need to update the consultation date
        console.log("Change");
        setModifyingConsulDate(false);
        setFinishingModifyingConsulDate(false);
        savePrevActions(
            student.uuid,
            null,
            "consultation_date",
            student.consultation_date, // prev value
            consulDateRef.current.value, // new value
            student.consultation_number
        );
        updatePotentialStudentsInfo(student.uuid, {
            ...student,
            consultation_date: consulDateRef.current.value
        });
        student.consultation_date = consulDateRef.current.value;
        setBeingModified(false);
    });

    const dateEmailSendRef = useOuterClick(e => {
        // if other input is being modified, return
        if (modifyingConsulDate || modifyingParentName || modifyingPhone || modifyingEmail || modifyingMemo > -1 || modifyingMemoTitle > -1) return;

        if (!finishingModifyingDateEmailSent) {
            setFinishingModifyingDateEmailSent(true);
            dateEmailSendRef.current.focus();
            setBeingModified(true);
            return;
        }

        if ((modifiedDateEmailSent === null && (student.info_mail === undefined || student.info_mail.date === null)) || (modifiedDateEmailSent.format("YYYY-MM-DD") === dayjs(student.info_mail.date).format("YYYY-MM-DD"))) {
            console.log("No change");
            setModifyingDateEmailSent(false);
            setFinishingModifyingDateEmailSent(false);
            setBeingModified(false);
            return;
        }

        // need to update the date email sent
        console.log("Change");
        setModifyingDateEmailSent(false);
        setFinishingModifyingDateEmailSent(false);
        savePrevActions(
            student.uuid,
            student.info_mail.id,
            "info_mail_date",
            dayjs(student.info_mail.date).format("YYYY-MM-DD"), // prev value
            modifiedDateEmailSent.format("YYYY-MM-DD"), // new value
            student.consultation_number
        );
        updateInfoMail(student.uuid, student.info_mail.id, {
            ...student.info_mail,
            date: modifiedDateEmailSent.hour(12),
        });
        student.info_mail.date = modifiedDateEmailSent;
        setBeingModified(false);
    });

    const parentNameRef = useOuterClick(e => {
        // if other input is being modified, return
        if (modifyingConsulDate || modifyingDateEmailSent || modifyingPhone || modifyingEmail || modifyingMemo > -1 || modifyingMemoTitle > -1) return;

        if (!finishingModifyingParentName) {
            setFinishingModifyingParentName(true);
            parentNameRef.current.focus();
            setBeingModified(true);
            return;
        };

        if (parentNameRef.current.value === student.parent_name || (parentNameRef.current.value === "" && student.parent_name === null)) {
            console.log("No change");
            setModifyingParentName(false);
            setFinishingModifyingParentName(false);
            setBeingModified(false);
            return;
        }

        // need to update the parent name
        console.log("Change");
        setModifyingParentName(false);
        setFinishingModifyingParentName(false);
        savePrevActions(
            student.uuid,
            null,
            "parent_name",
            student.parent_name, // prev value
            parentNameRef.current.value, // new value
            student.consultation_number
        );
        updatePotentialStudentsInfo(student.uuid, {
            ...student,
            parent_name: parentNameRef.current.value,
        });
        student.parent_name = parentNameRef.current.value;
        setBeingModified(false);
    });

    const phoneInnerRef = useOuterClick(e => {
        // if other input is being modified, return
        if (modifyingConsulDate || modifyingDateEmailSent || modifyingParentName || modifyingEmail || modifyingMemo > -1 || modifyingMemoTitle > -1) return;

        if (!finishingModifyingPhone) {
            setFinishingModifyingPhone(true);
            phoneInnerRef.current.focus();
            setBeingModified(true);
            return;
        };

        if (phoneInnerRef.current.value === student.parent_phone || (phoneInnerRef.current.value === "" && student.parent_phone === null)) {
            console.log("No change");
            setModifyingPhone(false);
            setFinishingModifyingPhone(false);
            setBeingModified(false);
            return;
        }

        // need to update the phone number
        console.log("Change");
        setModifyingPhone(false);
        setFinishingModifyingPhone(false);
        savePrevActions(
            student.uuid,
            null,
            "parent_phone",
            student.parent_phone, // prev value
            phoneInnerRef.current.value, // new value
            student.consultation_number
        );
        updatePotentialStudentsInfo(student.uuid, {
            ...student,
            parent_phone: phoneInnerRef.current.value,
        });
        student.parent_phone = phoneInnerRef.current.value;
        setBeingModified(false);
    });

    const emailInnerRef = useOuterClick(e => {
        // if other input is being modified, return
        if (modifyingConsulDate || modifyingDateEmailSent || modifyingParentName || modifyingPhone || modifyingMemo > -1 || modifyingMemoTitle > -1) return;

        if (!finishingModifyingEmail) {
            setFinishingModifyingEmail(true);
            emailInnerRef.current.focus();
            setBeingModified(true);
            return;
        };

        if (emailInnerRef.current.value === student.parent_email || (emailInnerRef.current.value === "" && student.parent_email === null)) {
            console.log("No change");
            setModifyingEmail(false);
            setFinishingModifyingEmail(false);
            setBeingModified(false);
            return;
        }

        // need to update the email
        console.log("Change");
        setModifyingEmail(false);
        setFinishingModifyingEmail(false);
        savePrevActions(
            student.uuid, null,
            "parent_email",
            student.parent_email, // prev value
            emailInnerRef.current.value, // new value
            student.consultation_number
        );
        updatePotentialStudentsInfo(student.uuid, {
            ...student,
            parent_email: emailInnerRef.current.value,
        });
        student.parent_email = emailInnerRef.current.value;
        setBeingModified(false);
    });

    const memoRef = useOuterClick(e => {
        // if other input is being modified, return
        if (modifyingConsulDate || modifyingDateEmailSent || modifyingParentName || modifyingPhone || modifyingEmail || modifyingMemo !== parseInt(memoRef.current.getAttribute("data-key")) || modifyingMemoTitle > -1) return;

        if (finishingModifyingMemo[0] !== parseInt(memoRef.current.getAttribute("data-key")) || (finishingModifyingMemo[0] === parseInt(memoRef.current.getAttribute("data-key")) && !finishingModifyingMemo[1])) {
            setFinishingModifyingMemo([parseInt(memoRef.current.getAttribute("data-key")), true]);
            memoRef.current.focus();
            setBeingModified(true);
            return;
        };

        const memo = student.memos.filter(memo => memo.id === parseInt(memoRef.current.getAttribute("data-key")))[0] ?? null;

        if (memo !== null && (memoRef.current.value === memo.content || (memoRef.current.value === "" && memo?.content === null))) {
            console.log("No change");
            setModifyingMemo(-1);
            setFinishingModifyingMemo([-1, false]);
            setBeingModified(false);
            return;
        }

        // need to update the memo
        console.log("Change");
        setModifyingMemo(-1);
        setFinishingModifyingMemo([-1, false]);
        savePrevActions(
            student.uuid,
            memo.id,
            "memo_content",
            memo.content, // prev value
            memoRef.current.value, // new value
            student.consultation_number
        );
        updateMemo(student.uuid, memo.id, {
            ...memo,
            content: memoRef.current.value,
        });
        memo.content = memoRef.current.value;
        setBeingModified(false);
    });

    const memoTitleRef = useOuterClick(e => {
        // if other input is being modified, return
        if (modifyingConsulDate || modifyingDateEmailSent || modifyingParentName || modifyingPhone || modifyingEmail || modifyingMemo > -1 || modifyingMemoTitle !== parseInt(memoTitleRef.current.getAttribute("data-key"))) return;

        if (finishingModifyingMemoTitle[0] !== parseInt(memoTitleRef.current.getAttribute("data-key")) || (finishingModifyingMemoTitle[0] === parseInt(memoTitleRef.current.getAttribute("data-key")) && !finishingModifyingMemoTitle[1])) {
            setFinishingModifyingMemoTitle([parseInt(memoTitleRef.current.getAttribute("data-key")), true]);
            memoTitleRef.current.focus();
            setBeingModified(true);
            return;
        }

        const memo = student.memos.filter(memo => memo.id === parseInt(memoTitleRef.current.getAttribute("data-key")))[0] ?? null;

        if (memo !== null && (memoTitleRef.current.value === memo.title || (memoTitleRef.current.value === "" && memo?.title === null))) {
            console.log("No change");
            setModifyingMemoTitle(-1);
            setFinishingModifyingMemoTitle([-1, false]);
            setBeingModified(false);
            return;
        }

        // need to update the memo title
        console.log("Change");
        setModifyingMemoTitle(-1);
        setFinishingModifyingMemoTitle([-1, false]);
        savePrevActions(
            student.uuid,
            memo.id,
            "memo_title",
            memo.title, // prev value
            memoTitleRef.current.value, // new value
            student.consultation_number
        );
        updateMemo(student.uuid, memo.id, {
            ...memo,
            title: memoTitleRef.current.value,
        });
        memo.title = memoTitleRef.current.value;
        setBeingModified(false);
    });

    function getDayInKorean(date) {
        const day = dayjs(date).day();
        switch (day) {
            case 0:
                return "일";
            case 1:
                return "월";
            case 2:
                return "화";
            case 3:
                return "수";
            case 4:
                return "목";
            case 5:
                return "금";
            case 6:
                return "토";
            default:
                return "";
        }
    }

    return (
        <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            layout
        >
            <Card
                sx={{
                    position: "relative",
                    minWidth: 600,
                    maxWidth: 600,
                    backgroundColor: colors.primary[600],
                    // boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                    border: `1px solid ${colors.primary[600]}`,
                    // ":hover": {
                    //     // boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
                    //     border: "1px solid grey",
                    // }
                }}
                key={student.id}
            >
                <CardContent
                    sx={{
                        paddingX: 0,
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", paddingX: '10px' }}>
                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 0.5,
                                mb: 2
                            }}
                        >
                            <Typography variant="h3">
                                {student.consultation_number ?? "N/A"}
                            </Typography>
                        </Box>
                        {student.is_potential_student && student.info_mail && !student.info_mail.is_sent
                            && <>
                                <CancelScheduleSendIcon fontSize='small' color='error' sx={{ cursor: "pointer" }} onClick={handleClickDialogOpen} />
                                <Dialog
                                    open={dialogOpen}
                                    onClose={handleDialogClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"Did you send an infomation mail to this potential student?"}
                                    </DialogTitle>
                                    <DialogActions>
                                        <Button onClick={(e) => handleInfoMailUpdate(e)} color="success" autoFocus>
                                            Yes
                                        </Button>
                                        <Button onClick={handleDialogClose}>No</Button>
                                    </DialogActions>
                                </Dialog>
                            </>}
                        {student.info_mail && student.info_mail.is_requested &&
                            <>
                                <CancelScheduleSendIcon fontSize='small' color='warning' sx={{ cursor: "pointer" }} onClick={handleClickDialogOpen} />
                                <Dialog
                                    open={dialogOpen}
                                    onClose={handleDialogClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"Did you send an infomation mail to this potential student?"}
                                    </DialogTitle>
                                    <DialogActions>
                                        <Button onClick={(e) => handleInfoMailUpdate(e)} color="success" autoFocus>
                                            Yes
                                        </Button>
                                        <Button onClick={handleDialogClose}>No</Button>
                                    </DialogActions>
                                </Dialog>
                            </>
                        }
                        <Tooltip title="Blacklist" sx={{ marginLeft: 2 }}>
                            {student.is_blacklist_student ? <PersonOffIcon /> : <></>}
                        </Tooltip>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            paddingX: '10px'
                        }}
                    >
                        <MoreInfoModal student={student} setStudentUpdated={setStudentUpdated} />
                        <Button
                            size="small"
                            sx={{
                                backgroundColor: colors.blueAccent[900],
                                color: colors.primary[400],
                                "&:hover": {
                                    backgroundColor: colors.blueAccent[800],
                                },
                            }}
                            onClick={() => {
                                // navigate(`/admin/potentialStudents/${student.uuid}/emailGenerator`);
                                window.open(`/admin/consultationProgress/${student.uuid}/emailGenerator`, "_blank");
                            }}
                        >
                            Generate Email
                        </Button>
                    </Box>
                    <br />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {/* Consultation Date */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: '100%',
                                minHeight: 50,
                                borderTop: `1px solid ${colors.grey[800]}`,
                                borderBottom: `1px solid ${colors.grey[800]}`,
                                textAlign: "center",
                            }}
                            onClick={() => {
                                if (modifyingDateEmailSent || modifyingParentName || modifyingPhone || modifyingEmail || modifyingMemo > -1 || modifyingMemoTitle > -1) return;
                                setModifyingConsulDate(true);
                            }}
                        >
                            {modifyingConsulDate ?
                                <input
                                    type="text"
                                    value={modifiedConsulDate}
                                    onChange={(e) => setModifiedConsulDate(e.target.value)}
                                    ref={consulDateRef}
                                    style={{
                                        flex: 1,
                                        width: "100%",
                                        height: "100%",
                                        textAlign: "center",
                                    }}
                                />
                                :
                                <Typography
                                    variant="h6"
                                    color={student.consultation_date ? "primary" : colors.grey[700]}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        flex: 1,
                                    }}
                                >
                                    {modifiedConsulDate !== "" ? modifiedConsulDate : "N/A"}
                                </Typography>
                            }
                        </Box>
                        {/* if they will register */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: '100%',
                                minHeight: 50,
                                borderBottom: `1px solid ${colors.grey[800]}`,
                                textAlign: "center",
                            }}
                        >
                        </Box>
                        {/* Date email sent */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: '100%',
                                minHeight: 70,
                                borderBottom: `1px solid ${colors.grey[800]}`,
                                textAlign: "center",
                                alignItems: "center",
                            }}
                            onClick={() => {
                                if (modifyingConsulDate || modifyingParentName || modifyingPhone || modifyingEmail || modifyingMemo > -1 || modifyingMemoTitle > -1) return;
                                setModifyingDateEmailSent(true);
                            }}
                        >
                            {modifyingDateEmailSent ?
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            label="Date"
                                            value={modifiedDateEmailSent}
                                            onChange={(newValue) => {
                                                if (newValue === null || newValue === undefined) return;
                                                setModifiedDateEmailSent(newValue);
                                            }}
                                            ref={dateEmailSendRef}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                :
                                <Typography
                                    variant="h6"
                                    color={student.info_mail?.date ? "primary" : colors.grey[700]}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        flex: 1,
                                    }}
                                >
                                    {modifiedDateEmailSent !== null ? dayjs(modifiedDateEmailSent).format("YYYY-MM-DD") + " " + getDayInKorean(modifiedDateEmailSent) : "N/A"}
                                </Typography>
                            }
                        </Box>
                        {/* Parent Name */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: '100%',
                                minHeight: 50,
                                borderBottom: `1px solid ${colors.grey[800]}`,
                                textAlign: "center",
                            }}
                            onClick={() => {
                                if (modifyingConsulDate || modifyingDateEmailSent || modifyingPhone || modifyingEmail || modifyingMemo > -1 || modifyingMemoTitle > -1) return;
                                setModifyingParentName(true);
                            }}
                        >
                            {modifyingParentName ?
                                <input
                                    type="text"
                                    value={modifiedParentName}
                                    onChange={(e) => setModifiedParentName(e.target.value)}
                                    ref={parentNameRef}
                                    style={{
                                        flex: 1,
                                        width: "100%",
                                        height: "100%",
                                        textAlign: "center",
                                    }}
                                />
                                :
                                <Typography
                                    variant="h6"
                                    color={student.parent_name ? "primary" : colors.grey[700]}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        flex: 1,
                                    }}
                                >
                                    {modifiedParentName !== "" ? modifiedParentName : "N/A"}
                                </Typography>
                            }
                        </Box>
                        {/* Parent Phone */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: '100%',
                                minHeight: 50,
                                borderBottom: `1px solid ${colors.grey[800]}`,
                                textAlign: "center",
                            }}
                            onClick={() => {
                                if (modifyingConsulDate || modifyingDateEmailSent || modifyingParentName || modifyingEmail || modifyingMemo > -1 || modifyingMemoTitle > -1) return;
                                setModifyingPhone(true);
                            }}
                        >
                            {modifyingPhone ?
                                <input
                                    type="text"
                                    value={modifiedPhone}
                                    onChange={(e) => setModifiedPhone(e.target.value)}
                                    ref={phoneInnerRef}
                                    style={{
                                        flex: 1,
                                        width: "100%",
                                        height: "100%",
                                        textAlign: "center",
                                    }}
                                />
                                :
                                <Typography
                                    variant="h6"
                                    color={student.parent_phone ? "primary" : colors.grey[700]}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        flex: 1,
                                    }}
                                >
                                    {modifiedPhone !== "" ? modifiedPhone : "N/A"}
                                </Typography>
                            }
                        </Box>
                        {/* Parent Email */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: '100%',
                                minHeight: 50,
                                borderBottom: `1px solid ${colors.grey[800]}`,
                                textAlign: "center",
                            }}
                            onClick={() => {
                                if (modifyingConsulDate || modifyingDateEmailSent || modifyingParentName || modifyingPhone || modifyingMemo > -1 || modifyingMemoTitle > -1) return;
                                setModifyingEmail(true);
                            }}
                        >
                            {modifyingEmail ?
                                <input
                                    type="text"
                                    value={modifiedEmail}
                                    onChange={(e) => setModifiedEmail(e.target.value)}
                                    ref={emailInnerRef}
                                    style={{
                                        flex: 1,
                                        width: "100%",
                                        height: "100%",
                                        textAlign: "center",
                                    }}
                                />
                                :
                                <Typography
                                    variant="h6"
                                    color={student.parent_email ? "primary" : colors.grey[700]}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        flex: 1,
                                    }}
                                >
                                    {modifiedEmail !== "" ? modifiedEmail : "N/A"}
                                </Typography>
                            }
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                width: '100%',
                                minHeight: 50,
                                borderBottom: `1px solid ${colors.grey[800]}`,
                                textAlign: "center",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                    cursor: "pointer",
                                }}
                                onClick={async () => {
                                    if (modifyingConsulDate || modifyingDateEmailSent || modifyingParentName || modifyingPhone || modifyingEmail || modifyingMemo > -1 || modifyingMemoTitle > -1) return;
                                    const value = !modifiedIsRequested;
                                    try {
                                        let response;
                                        if (student.info_mail === undefined || student.info_mail === null) {
                                            response = await Axios.post(
                                                `${process.env.REACT_APP_URL}/api/v1/students/${student.uuid}/info-mails`,
                                                {
                                                    is_requested: value,
                                                },
                                                {
                                                    headers: {
                                                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                                                    },
                                                }
                                            )

                                            const updatedStudentResponse = await Axios.get(
                                                `${process.env.REACT_APP_URL}/api/v1/students/${student.uuid}`,
                                                {
                                                    headers: {
                                                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                                                    },
                                                }
                                            );

                                            savePrevActions(
                                                student.uuid,
                                                updatedStudentResponse.data.info_mail.id,
                                                "info_mail_is_requested",
                                                false, // prev value (it didn't exist before)
                                                value,
                                                student.consultation_number
                                            );

                                            Object.assign(student, updatedStudentResponse.data);
                                        } else {
                                            response = await Axios.put(
                                                `${process.env.REACT_APP_URL}/api/v1/students/${student.uuid}/info-mails/${student.info_mail.id}`,
                                                {
                                                    is_requested: value,
                                                },
                                                {
                                                    headers: {
                                                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                                                    },
                                                }
                                            );
                                        }

                                        if (response.status === 200 || response.status === 201) {
                                            console.log(student.info_mail);
                                            setModifiedIsRequested(value);
                                            student.info_mail.is_requested = value;

                                            savePrevActions(
                                                student.uuid,
                                                student.info_mail.id,
                                                "info_mail_is_requested",
                                                !value,
                                                value,
                                                student.consultation_number
                                            );

                                            setStudentUpdated(true);
                                            setSuccessAlertMessage("Info mail request status updated successfully!");
                                            setSuccessAlertOpen(true);
                                        }
                                    } catch (error) {
                                        console.error("Error updating is_requested:", error);
                                        setErrorAlertMessage(error.response?.data?.message || "Failed to update info mail request status");
                                        setErrorAlertOpen(true);
                                    }
                                }}
                            >
                                <Typography variant="h6" color={colors.grey[400]}>
                                    다시 연락 옴 안내하기:
                                </Typography>
                                <input
                                    type="checkbox"
                                    checked={modifiedIsRequested}
                                    readOnly
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        cursor: "pointer",
                                    }}
                                />
                            </Box>
                        </Box>
                        {/* Consultation Subjects (It is Memos of the potential student data) */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: '100%',
                                minHeight: '100%',
                                textAlign: "center",
                                padding: "0 20px",
                            }}
                        >
                            {student.memos?.sort(
                                (a, b) => {
                                    if (a.order === null && b.order === null) {
                                        return a.createdAt > b.createdAt ? -1 : 1;
                                    }
                                    if (a.order === null) {
                                        return -1;
                                    }
                                    if (b.order === null) {
                                        return 1;
                                    }
                                    return a.order < b.order ? -1 : 1;
                                }
                            ).map((memo, index) => {
                                if (memo === undefined || memo === null) return;

                                if (index === 0) {
                                    return (
                                        <Box
                                            key={memo.id}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 1,
                                                width: '100%',
                                                minHeight: "400px",
                                                textAlign: "center",
                                                padding: "20px 0 40px 0",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: 1,
                                                    width: '100%',
                                                    textAlign: "center",
                                                }}
                                                onClick={() => {
                                                    if (modifyingConsulDate || modifyingDateEmailSent || modifyingParentName || modifyingPhone || modifyingEmail || modifyingMemo > -1 || modifyingMemoTitle > -1) return;
                                                    setModifyingMemoTitle(memo.id);
                                                }}
                                            >
                                                {modifyingMemoTitle === memo.id ?
                                                    <input
                                                        type="text"
                                                        value={modifiedMemo.get(memo.id)?.title}
                                                        onChange={(e) => {
                                                            const newMemo = new Map(modifiedMemo);
                                                            newMemo.set(memo.id, { ...memo, title: e.target.value });
                                                            setModifiedMemo(newMemo);
                                                        }}
                                                        ref={memoTitleRef}
                                                        data-key={memo.id}
                                                        style={{
                                                            width: "100%",
                                                            textAlign: "center",
                                                            padding: "10px 0",
                                                        }}
                                                    />
                                                    :
                                                    <Typography variant="h6" color="primary" sx={{ padding: "10px 0" }}>
                                                        {modifiedMemo.get(memo.id)?.title} {modifiedMemo.get(memo.id)?.date ? `(${modifiedMemo.get(memo.id)?.date})` : ""}
                                                    </Typography>
                                                }
                                            </Box>
                                            <Box
                                                sx={{
                                                    flex: 1,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: 1,
                                                    width: '100%',
                                                    height: "100%",
                                                    textAlign: "center",
                                                }}
                                                onClick={() => {
                                                    if (modifyingConsulDate || modifyingDateEmailSent || modifyingParentName || modifyingPhone || modifyingEmail || modifyingMemo > -1 || modifyingMemoTitle > -1) return;
                                                    setModifyingMemo(memo.id)
                                                }}
                                            >
                                                {modifyingMemo === memo.id ?
                                                    <textarea
                                                        type="text"
                                                        value={modifiedMemo.get(memo.id)?.content}
                                                        onChange={(e) => {
                                                            const newMemo = new Map(modifiedMemo);
                                                            newMemo.set(memo.id, { ...memo, content: e.target.value });
                                                            setModifiedMemo(newMemo);
                                                        }}
                                                        rows={
                                                            (modifiedMemo.get(memo.id)?.content?.split('\n')?.length * 2) > 10 ?
                                                                (modifiedMemo.get(memo.id)?.content?.split('\n')?.length * 2) :
                                                                10
                                                        }
                                                        ref={memoRef}
                                                        data-key={memo.id}
                                                        style={{
                                                            flex: 1,
                                                            width: "100%",
                                                            height: "100%",
                                                            textAlign: "center",
                                                        }}
                                                    />
                                                    :
                                                    modifiedMemo.get(memo.id)?.content?.split('\n').map((line, index) => {
                                                        return (
                                                            <Typography key={index}>
                                                                {line}
                                                            </Typography>
                                                        );
                                                    })
                                                }
                                            </Box>
                                        </Box>
                                    );
                                }

                                return (
                                    moreMemos &&
                                    <Box
                                        key={memo.id}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 1,
                                            width: '100%',
                                            minHeight: "400px",
                                            borderTop: `1px solid ${colors.grey[800]}`,
                                            textAlign: "center",
                                            padding: "20px 0",
                                            marginBottom: index === student.memos.length - 1 ? "20px" : "0",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 1,
                                                width: '100%',
                                                textAlign: "center",
                                            }}
                                            onClick={() => {
                                                if (modifyingConsulDate || modifyingDateEmailSent || modifyingParentName || modifyingPhone || modifyingEmail || modifyingMemo > -1 || modifyingMemoTitle > -1) return;
                                                setModifyingMemoTitle(memo.id)
                                            }}
                                        >
                                            {modifyingMemoTitle === memo.id ?
                                                <input
                                                    type="text"
                                                    value={modifiedMemo.get(memo.id)?.title}
                                                    onChange={(e) => {
                                                        const newMemo = new Map(modifiedMemo);
                                                        newMemo.set(memo.id, { ...memo, title: e.target.value });
                                                        setModifiedMemo(newMemo);
                                                    }}
                                                    ref={memoTitleRef}
                                                    data-key={memo.id}
                                                    style={{
                                                        width: "100%",
                                                        textAlign: "center",
                                                        padding: "10px 0",
                                                    }}
                                                />
                                                :
                                                <Typography variant="h6" color="primary" sx={{ padding: "10px 0" }}>
                                                    {modifiedMemo.get(memo.id)?.title} {modifiedMemo.get(memo.id)?.date ? `(${modifiedMemo.get(memo.id)?.date})` : ""}
                                                </Typography>
                                            }
                                        </Box>
                                        <Box
                                            sx={{
                                                flex: 1,
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: 1,
                                                width: '100%',
                                                height: "100%",
                                                textAlign: "center",
                                            }}
                                            onClick={() => {
                                                if (modifyingConsulDate || modifyingDateEmailSent || modifyingParentName || modifyingPhone || modifyingEmail || modifyingMemo > -1 || modifyingMemoTitle > -1) return;
                                                setModifyingMemo(memo.id)
                                            }}
                                        >
                                            {modifyingMemo === memo.id ?
                                                <textarea
                                                    type="text"
                                                    value={modifiedMemo.get(memo.id)?.content}
                                                    onChange={(e) => {
                                                        const newMemo = new Map(modifiedMemo);
                                                        newMemo.set(memo.id, { ...memo, content: e.target.value });
                                                        setModifiedMemo(newMemo);
                                                    }}
                                                    rows={
                                                        (modifiedMemo.get(memo.id)?.content?.split('\n')?.length * 2) > 10 ?
                                                            (modifiedMemo.get(memo.id)?.content?.split('\n')?.length * 2) :
                                                            10
                                                    }
                                                    ref={memoRef}
                                                    data-key={memo.id}
                                                    style={{
                                                        flex: 1,
                                                        width: "100%",
                                                        height: "100%",
                                                        textAlign: "center",
                                                    }}
                                                />
                                                :
                                                modifiedMemo.get(memo.id)?.content?.split('\n').map((line, index) => {
                                                    return (
                                                        <Typography key={index}>
                                                            {line}
                                                        </Typography>
                                                    );
                                                })
                                            }
                                        </Box>
                                    </Box>
                                );
                            })}
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 1,
                                    width: '100%',
                                    textAlign: "center",
                                    marginTop: "20px",
                                    marginBottom: "60px",
                                }}
                            >
                                <input
                                    type="text"
                                    value={newMemoTitle}
                                    onChange={(e) => setNewMemoTitle(e.target.value)}
                                    placeholder='Title'
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        padding: "10px 0",
                                    }}
                                />
                                <textarea
                                    type="text"
                                    value={newMemoContent}
                                    onChange={(e) => setNewMemoContent(e.target.value)}
                                    rows={10}
                                    style={{
                                        flex: 1,
                                        width: "100%",
                                        height: "100%",
                                        textAlign: "center",
                                        marginBottom: "10px",
                                    }}
                                />
                                <AddMemoDatePicker reminderDate={newMemoReminder} setReminderDate={setNewMemoReminder} />
                            </Box>
                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 1,
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "20px",
                                        textAlign: "center",
                                        padding: "20px",
                                        marginBottom: "10px",
                                        backgroundColor: colors.greenAccent[700],
                                    }}
                                    onClick={(e) => uploadNewMemo(e)}
                                >
                                    <Typography variant="h6" color={colors.primary[400]}>
                                        Add Memo
                                    </Typography>
                                </Button>
                                {(student.memos?.length || 0) > 1 &&
                                    <Button
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            height: "20px",
                                            textAlign: "center",
                                            padding: "20px",
                                            marginBottom: "10px",
                                            backgroundColor: colors.greenAccent[500],
                                        }}
                                        onClick={() => setMoreMemos(!moreMemos)}
                                    >
                                        <Typography variant="h6" color={colors.primary[400]}>
                                            {moreMemos ? "Less" : "More"}
                                        </Typography>
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <Alert
                successAlertOpen={successAlertOpen}
                setSuccessAlertOpen={setSuccessAlertOpen}
                errorAlertOpen={errorAlertOpen}
                setErrorAlertOpen={setErrorAlertOpen}
                successMsg={successAlertMessage}
                errorMsg={errorAlertMessage}
                vertical='bottom'
                horizontal='left'
            />
        </motion.div>
    );
}