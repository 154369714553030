import * as React from 'react';
import { tokens } from "../../theme";
import { useTheme, TextField, ListItemText } from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Axios from 'axios';
import EventModal from './EventModal';
import dayjs from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc)
dayjs.extend(timezone)

export default function MoreInfoModal({
    open,
    handleClose,
    competition,
    setCompetitionUpdated,
    openEvents,
    deadlineEvents,
    testEvents,
    winnerEvents,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertOpen,
    setErrorAlertMessage,
}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // State for updating competition name
    const [openCompetitonNameUpdate, setOpenCompetitonNameUpdate] = React.useState(false);
    const handleOpenCompetitonNameUpdate = () => setOpenCompetitonNameUpdate(true);
    const handleCloseCompetitonNameUpdate = () => setOpenCompetitonNameUpdate(false);

    // for subject availability
    const subjectCategories = [
        "Biology",
        "Chemistry",
        "Physics",
        "Computer Science",
        "Economics",
        "English",
        "History",
        "Math",
        "Research",
        "Writing Competition",
        "Test Prep",
        "Social",
    ];

    const [selectedSubject, setSelectedSubject] = React.useState(competition ? competition.subject : '');
    const handleSubjectChange = (event) => {
        setSelectedSubject(event.target.value);
        setOpenSubjectSelect(false);
    };

    const [openSubjectSelect, setOpenSubjectSelect] = React.useState(false);

    // Update competition info
    const handleUpdateCompetitonInfo = async (event) => {
        event.preventDefault();

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/competitions/${competition.id}`, {
            competition_name: competition.name,
            competiton_url: competition.url,
            competition_subject: selectedSubject,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Competition name has been updated!");
            setSuccessAlertOpen(true);
            setCompetitionUpdated(true);
            setOpenCompetitonNameUpdate(false);
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    };

    // State for memo
    const [eligibilityMemo, setEligibilityMemo] = React.useState(competition?.eligibility_memo || '');
    const [testFormatMemo, setTestFormatMemo] = React.useState(competition?.test_format_memo || '');
    const [memo, setMemo] = React.useState(competition?.memo || '');    

    // This effect updates memo when the competition data changes
    React.useEffect(() => {
        if (competition) {
            setMemo(competition.memo || '');
            setEligibilityMemo(competition.eligibility_memo || '');
            setTestFormatMemo(competition.test_format_memo || '');
        }
    }, [competition]);

    const handleEligibilityMemoChange = (event) => {
        setEligibilityMemo(event.target.value);
    };

    const handleTestFormatMemoChange = (event) => {
        setTestFormatMemo(event.target.value);
    };

    const handleMemoChange = (event) => {
        setMemo(event.target.value);
    };

    const handleMemoSave = async () => {
        if (!competition) return;

        try {
            await Axios.put(`${process.env.REACT_APP_URL}/api/v1/competitions/${competition.id}/memo`, {
                memo: memo,
                test_format_memo: testFormatMemo,
                eligibility_memo: eligibilityMemo,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            });
            setSuccessAlertMessage("Memo has been updated!");
            setSuccessAlertOpen(true);
            setCompetitionUpdated(true);
        } catch (error) {
            console.error("Failed to save memo:", error);
        }
    };

    const [openAddEventModal, setOpenAddEventModal] = React.useState(false);
    const [eventDetails, setEventDetails] = React.useState({ 
        id: '', 
        name: '', 
        date: dayjs().tz("America/New_York"), 
        color: '#000000', 
        fixed_event_name: '', 
    });

    const handleAddEventClick = (eventType) => {
        setEventDetails({
            color: 
                eventType === "open" ? "#00b050" : 
                eventType === "test" ? "#0070c0" : 
                eventType === "winner_announcement" ? "#a02b93" : "#000000",
            fixed_event_name: eventType,
        });
        setOpenAddEventModal(true);
    };

    const handleCloseAddEventModal = () => {        
        setOpenAddEventModal(false);
    };

    const [openUpdateEventModal, setOpenUpdateEventModal] = React.useState(false);

    const handleOpenUpdateEventModal = (event) => {
        setOpenUpdateEventModal(true);
        setEventDetails(event);
    };

    const handleCloseUpdateEventModal = () => {
        setEventDetails({ 
            id: '', 
            name: '', 
            date: '', 
            color: '#000000',
            fixed_event_name: '', 
        });
        setOpenUpdateEventModal(false);
    };

    const handleEventSubmit = async () => {

        await Axios.post(`${process.env.REACT_APP_URL}/api/v1/calendar-events`, {
            name: eventDetails.name,
            date: eventDetails.date,
            color: eventDetails.color,
            competition_id: competition.id,
            fixed_event_name: eventDetails.fixed_event_name,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Event has been added!");
            setSuccessAlertOpen(true);
            setCompetitionUpdated(true);
            setEventDetails({ id: '', name: '', date: '', color: '#000000', fixed_event_name: '' });
            handleCloseAddEventModal();
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    };

    const handleEventUpdate = async () => {

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/calendar-events/${eventDetails.id}`, {
            name: eventDetails.name,
            date: eventDetails.date,
            color: eventDetails.color,
            competition_id: competition.id,
            fixed_event_name: eventDetails.fixed_event_name,
        }, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Event has been updated!");
            setSuccessAlertOpen(true);
            setCompetitionUpdated(true);
            setEventDetails({ id: '', name: '', date: '', color: '#000000', fixed_event_name: '' });
            handleCloseUpdateEventModal();
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    };

    const handleEventDelete = async (e, id) => {
        e.stopPropagation();

        await Axios.delete(`${process.env.REACT_APP_URL}/api/v1/calendar-events/${id}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage("Event has been deleted!");
            setSuccessAlertOpen(true);
            setCompetitionUpdated(true);
            setOpenAddEventModal(false);
        }).catch((error) => {
            console.log(error);
            setErrorAlertMessage(error.response.data.message);
            setErrorAlertOpen(true);
        });
    };

    const handleColorSelect = (color) => {
        setEventDetails({ ...eventDetails, color });
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '85%',
        height: '85%',
        bgcolor: colors.primary[600],
        border: '2px solid grey',
        boxShadow: 24,
        p: 4,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    };

    const competitionInfoChangeModalStyle = {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        p: 4,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "20%",
        height: "35%",
        bgcolor: colors.primary[600],
        border: '2px solid #000',
    };

    const handleCompetitionNameChange = (event) => {
        competition.name = event.target.value;
    };

    const handleUrlChange = (event) => {
        competition.url = event.target.value;
    };

    return (
        competition &&
            <div>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 2,
                                borderBottom: 1,
                            }}
                        >
                            <Typography variant="h2">
                                {competition.name}
                            </Typography>
                            <Link href={competition.url} variant="h6" target="_blank">
                                {competition.url ? "Go to Homepage" : null}
                            </Link>

                            <Tooltip title="Change Competition Name" placement="top">
                                <Button onClick={handleOpenCompetitonNameUpdate}>
                                    <EditIcon />
                                </Button>
                            </Tooltip>
                        </Box>

                        <Box
                            display="grid"
                            gap="20px"
                            gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                            gridTemplateRows="repeat(2, minmax(240px, auto))"
                            gridAutoFlow="row"
                            width="100%"
                            height="100%"
                            minHeight="600px"
                        >

                            {/* Section 1: Open */}
                            <Box 
                                gridColumn="span 1" 
                                sx={{ 
                                    padding: 2, 
                                    border: `1px solid ${colors.primary[700]}`, 
                                    overflowY: 'auto',
                                    // height: '300px',
                                }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                    <Typography variant="h6" fontWeight='bold'>Open</Typography>

                                    <Tooltip title="Add Open Event" placement="top">
                                        <IconButton
                                            onClick={() => handleAddEventClick("open")}
                                            sx={{ borderRadius: '50%', bgcolor: "lightgrey", color: '#fff' }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>

                                {/* Scrollable Events List */}
                                <Box
                                    sx={{
                                        borderRadius: '4px',
                                        border: `1px solid ${colors.primary[700]}`,
                                    }}
                                >
                                    {openEvents && openEvents?.length > 0 ? (
                                        openEvents.map((event) => (
                                            <Box
                                                key={event.id}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    borderBottom: `1px solid ${colors.primary[700]}`,
                                                }}
                                            >
                                                <Box sx={{ width: '35%', ml: 1 }}>
                                                    <Typography variant="body1" sx={{ color: event.color }}>{event.name}</Typography>
                                                </Box>
                                                <Box sx={{ width: '20%' }}>
                                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                                        {dayjs(event.date).tz("America/New_York").format('YYYY/MM/DD')}
                                                    </Typography>
                                                </Box>
                                             
                                                <Box sx={{ width: '15%', display: 'flex' }}>
                                                <IconButton sx={{ color: 'primary.main' }} onClick={() => handleOpenUpdateEventModal(event)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton sx={{ color: 'error.main' }} onClick={(e) => handleEventDelete(e, event.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                </Box>
                                            </Box>
                                        ))
                                    ) : (
                                        <Typography variant="body2" align="center">
                                            No events available
                                        </Typography>
                                    )}
                                </Box>
                            </Box>

                            {/* Section 2: Eligibility */}
                            <Box
                                gridColumn="span 1"
                                sx={{
                                    padding: 2,
                                    border: `1px solid ${colors.primary[700]}`,
                                    overflowY: 'auto',
                                    // height: '300px',
                                }}
                            >
                                <Typography variant="h6" fontWeight='bold' mb={1}>Eligibility</Typography>
                                <TextField
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    value={eligibilityMemo}
                                    onChange={handleEligibilityMemoChange}
                                    placeholder="Enter eligibility details here"
                                    variant="outlined"
                                />
                            </Box>

                            {/* Section 3: Test Dates */}
                            <Box 
                                gridColumn="span 1" 
                                sx={{ 
                                    padding: 2, 
                                    border: `1px solid ${colors.primary[700]}`, 
                                    overflowY: 'auto',
                                    // height: '300px',
                                }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                    <Typography variant="h6" fontWeight='bold'>Test Date</Typography>

                                    <Tooltip title="Add Test Event" placement="top">
                                        <IconButton
                                            onClick={() => handleAddEventClick("test")}
                                            sx={{ borderRadius: '50%', bgcolor: "lightgrey", color: '#fff' }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>

                                {/* Scrollable Events List */}
                                <Box
                                    sx={{
                                        borderRadius: '4px',
                                        border: `1px solid ${colors.primary[700]}`,
                                    }}
                                >
                                    {testEvents && testEvents?.length > 0 ? (
                                        testEvents.map((event) => (
                                            <Box
                                                key={event.id}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    borderBottom: `1px solid ${colors.primary[700]}`,
                                                }}
                                            >
                                                <Box sx={{ width: '35%', ml: 1 }}>
                                                    <Typography variant="body1" sx={{ color: event.color }}>{event.name}</Typography>
                                                </Box>
                                                <Box sx={{ width: '20%' }}>
                                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                                        {dayjs(event.date).tz("America/New_York").format('YYYY/MM/DD')}
                                                    </Typography>
                                                </Box>
                                             
                                                <Box sx={{ width: '15%', display: 'flex' }}>
                                                <IconButton sx={{ color: 'primary.main' }} onClick={() => handleOpenUpdateEventModal(event)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton sx={{ color: 'error.main' }} onClick={(e) => handleEventDelete(e, event.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                </Box>
                                            </Box>
                                        ))
                                    ) : (
                                        <Typography variant="body2" align="center">
                                            No events available
                                        </Typography>
                                    )}
                                </Box>
                            </Box>

                            {/* Section 4: Deadline */}
                            <Box 
                                gridColumn="span 1" 
                                sx={{ 
                                    padding: 2, 
                                    border: `1px solid ${colors.primary[700]}`, 
                                    overflowY: 'auto',
                                    // height: '300px',
                                }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                    <Typography variant="h6" fontWeight='bold'>Deadline</Typography>

                                    <Tooltip title="Add Deadline Event" placement="top">
                                        <IconButton
                                            onClick={() => handleAddEventClick("deadline")}
                                            sx={{ borderRadius: '50%', bgcolor: "lightgrey", color: '#fff' }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>

                                {/* Scrollable Events List */}
                                <Box
                                    sx={{
                                        borderRadius: '4px',
                                        border: `1px solid ${colors.primary[700]}`,
                                    }}
                                >
                                    {deadlineEvents && deadlineEvents?.length > 0 ? (
                                        deadlineEvents.map((event) => (
                                            <Box
                                                key={event.id}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    borderBottom: `1px solid ${colors.primary[700]}`,
                                                }}
                                            >
                                                <Box sx={{ width: '35%', ml: 1 }}>
                                                    <Typography variant="body1" sx={{ color: event.color }}>{event.name}</Typography>
                                                </Box>
                                                <Box sx={{ width: '20%' }}>
                                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                                        {dayjs(event.date).tz("America/New_York").format('YYYY/MM/DD')}
                                                    </Typography>
                                                </Box>
                                             
                                                <Box sx={{ width: '15%', display: 'flex' }}>
                                                <IconButton sx={{ color: 'primary.main' }} onClick={() => handleOpenUpdateEventModal(event)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton sx={{ color: 'error.main' }} onClick={(e) => handleEventDelete(e, event.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                </Box>
                                            </Box>
                                        ))
                                    ) : (
                                        <Typography variant="body2" align="center">
                                            No events available
                                        </Typography>
                                    )}
                                </Box>
                            </Box>

                            {/* Section 5: Winner Announcement */}
                            <Box 
                                gridColumn="span 1" 
                                sx={{ 
                                    padding: 2, 
                                    border: `1px solid ${colors.primary[700]}`, 
                                    overflowY: 'auto',
                                    // height: '300px',
                                }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                    <Typography variant="h6" fontWeight='bold'>Winner Announcement</Typography>

                                    <Tooltip title="Add Winner Announcement Event" placement="top">
                                        <IconButton
                                            onClick={() => handleAddEventClick("winner_announcement")}
                                            sx={{ borderRadius: '50%', bgcolor: "lightgrey", color: '#fff' }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>

                                {/* Scrollable Events List */}
                                <Box
                                    sx={{
                                        borderRadius: '4px',
                                        border: `1px solid ${colors.primary[700]}`,
                                    }}
                                >
                                    {winnerEvents && winnerEvents?.length > 0 ? (
                                        winnerEvents.map((event) => (
                                            <Box
                                                key={event.id}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    borderBottom: `1px solid ${colors.primary[700]}`,
                                                }}
                                            >
                                                <Box sx={{ width: '35%', ml: 1 }}>
                                                    <Typography variant="body1" sx={{ color: event.color }}>{event.name}</Typography>
                                                </Box>
                                                <Box sx={{ width: '20%' }}>
                                                    <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                                        {dayjs(event.date).tz("America/New_York").format('YYYY/MM/DD')}
                                                    </Typography>
                                                </Box>
                                             
                                                <Box sx={{ width: '15%', display: 'flex' }}>
                                                <IconButton sx={{ color: 'primary.main' }} onClick={() => handleOpenUpdateEventModal(event)}>
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton sx={{ color: 'error.main' }} onClick={(e) => handleEventDelete(e, event.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                                </Box>
                                            </Box>
                                        ))
                                    ) : (
                                        <Typography variant="body2" align="center">
                                            No events available
                                        </Typography>
                                    )}
                                </Box>
                            </Box>

                            {/* Section 6: Test Rules */}
                            <Box
                                gridColumn="span 1"
                                sx={{
                                    padding: 2,
                                    border: `1px solid ${colors.primary[700]}`,
                                    overflowY: 'auto',
                                    // height: '300px',
                                }}
                            >
                                <Typography variant="h6" fontWeight='bold' mb={1}>Test Rules</Typography>
                                <TextField
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    value={testFormatMemo}
                                    onChange={handleTestFormatMemoChange}
                                    placeholder="Enter test format details here"
                                    variant="outlined"
                                />
                            </Box>
                        </Box>
                        {/* Section 5: General Memo (takes the full width at the bottom) */}
                        <Box
                            // gridColumn="span 3"
                            sx={{
                                mt: 5,
                                padding: 2,
                                border: `1px solid ${colors.primary[700]}`,
                                // height: '180px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            }}
                        >
                            <TextField
                                fullWidth
                                multiline
                                value={memo}
                                onChange={handleMemoChange}
                                placeholder="Add a memo for this competition"
                                variant="outlined"
                            />

                            {/* Save Button */}
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={handleMemoSave}
                                sx={{ mt: 1, mb: 1 }}
                            >
                                Save Memo
                            </Button>
                        </Box>
                    </Box>
                </Modal>

                {/* Update Competition Name Modal */}
                <Modal
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    open={openCompetitonNameUpdate}
                    onClose={handleCloseCompetitonNameUpdate}
                >
                    <Fade in={openCompetitonNameUpdate}>
                        <Box sx={competitionInfoChangeModalStyle}>
                            <Box 
                                sx={{ 
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: 4,
                                    width: "100%",
                                }}
                            >
                                {/* Name */}
                                <TextField
                                    id="input-with-icon-textfield"
                                    label="Title of the competition?"
                                    variant="standard"
                                    onChange={handleCompetitionNameChange}
                                    fullWidth
                                    defaultValue={competition.name}
                                />
                                {/* URL */}
                                <TextField
                                    id="input-with-icon-textfield"
                                    label="URL of the competition homepage?"
                                    variant="standard"
                                    onChange={handleUrlChange}
                                    fullWidth
                                    defaultValue={competition.url}
                                />
                                {/* Subject */}
                                <FormControl variant="filled" sx={{ width: '100%' }}>
                                <InputLabel id="subject">Subject</InputLabel>
                                    <Select
                                        labelId="subject"
                                        id="subject"
                                        value={selectedSubject}
                                        onChange={handleSubjectChange}
                                        onOpen={() => setOpenSubjectSelect(true)}
                                        onClose={() => setOpenSubjectSelect(false)}
                                        open={openSubjectSelect}
                                        renderValue={(value) => value || 'Select a subject'}
                                    >
                                        {subjectCategories.map((subject, index) => (
                                            <MenuItem key={index} value={subject}>
                                                <ListItemText primary={subject} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button
                                    variant='contained'
                                    onClick={handleUpdateCompetitonInfo}
                                >
                                    Update
                                </Button>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>

                {/* Event Modal */}
                <EventModal
                    openAddEventModal={openAddEventModal}
                    handleCloseAddEventModal={handleCloseAddEventModal}
                    openUpdateEventModal={openUpdateEventModal}
                    handleCloseUpdateEventModal={handleCloseUpdateEventModal}
                    eventDetails={eventDetails}
                    setEventDetails={setEventDetails}
                    handleEventSubmit={handleEventSubmit}
                    handleEventUpdate={handleEventUpdate}
                    handleColorSelect={handleColorSelect}
                />
            </div>
    );
};