import React from 'react';
import './Calendar.css';
import ColorPicker from '../../components/ColorPicker';
import { Box, Button, Typography, TextField, Autocomplete, useTheme } from "@mui/material";
import { tokens } from '../../theme';
import Axios from 'axios';
import Popover from '@mui/material/Popover';
import Modal from '@mui/material/Modal';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import MiniCalendarsModal from './MiniCalendarsModal';
import AreYouSureModal from '../../components/AreYouSureModal';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const CalendarEventPopup = ({
    vacations,
    anchorEl, 
    setAnchorEl, 
    updateAnchorEl, 
    setUpdateAnchorEl, 
    selectedEvent,
    updated,
    setUpdated,
    year,
    month,
    activeDay,
    setSuccessAlertOpen,
    setSuccessAlertMessage,
    setErrorAlertMessage,
    setErrorAlertOpen,
}) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [calculatorInput, setCalculatorInput] = React.useState({
        weeks: '',
        date: null,
        event: null,
        eventId: '',
    });
    
    const [events, setEvents] = React.useState([]);

    // for are you sure modal
    const [areYouSureOpen, setAreYouSureOpen] = React.useState(false);

    React.useEffect(() => {
        const eventListup = () => {
            Axios.get(`${process.env.REACT_APP_URL}/api/v1/all-events`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setEvents(response.data);
            }).catch((error) => {
                if (error.response.status === 404) {
                console.log("No events found");
                }
            });
        };

        eventListup();
    }, [updated]);

    const eventsInfo = events.map((event) => ({
        name: event.name,
        value: event
    }));

    const options = [
        { label: 'Add (+)', value: true },
        { label: 'Subtract (-)', value: false },
    ];

    const calculateMethodOptions = [
        { label: 'Weeks', value: 'weeks' },
        { label: 'Dates', value: 'dates' },
        { label: 'Events', value: 'events' },
    ];
    
    // For Calculator
    const [openModal, setOpenModal] = React.useState(false);
    const [openCalculatorModal, setOpenCalculatorModal] = React.useState(false);

    // For options popover
    const [calculateOptionAnchorEl, setCalculateOptionAnchorEl] = React.useState(null);

    const [calculateMethod, setCalculateMethod] = React.useState(calculateMethodOptions[0]);

    const handleCalculateOptionsClick = (event) => {
        setCalculateOptionAnchorEl(event.currentTarget);
    };

    const handleCloseCalculateOptions = () => {
        setCalculateOptionAnchorEl(null);
    };

    const openCalculateOptions = Boolean(calculateOptionAnchorEl);
    const calculateOptionsId = openCalculateOptions ? 'calculate-options-popover' : undefined;

    
    // For Mini Calendar
    const [openMiniCalendarModal, setOpenMiniCalendarModal] = React.useState(false);
    const [dateRange, setDateRange] = React.useState({ startDate: dayjs(), endDate: dayjs() });
    const pickedDate = selectedEvent ? selectedEvent.date : dayjs(`${year}-${month + 1}-${activeDay}`, 'YYYY-M-D');
    

    const [value, setValue] = React.useState(options[1]);
    const [inputValue, setInputValue] = React.useState('');

    // Helper function to check if the current week is a vacation week
    const isVacation = (weekStart, weekEnd) => {
        return vacations.some(vacation =>
            dayjs(vacation.from).isSameOrBefore(weekEnd) && dayjs(vacation.to).isSameOrAfter(weekStart)
        );
    };

    const calculateDateRange = (currentDate, weeks, isAdding) => {

        const weeksInDays = weeks * 7;
        let startDate = null;
        let endDate = null;

        if (isAdding === true) {
            // if isAdding, need to find Next Monday (if it is Monday, then it is the same day)
            while (true) {
                if (dayjs(currentDate).day() === 1) {
                    startDate = currentDate;
                    break;
                }
                currentDate = dayjs(currentDate).add(1, 'day');
            }
        } else {
            // if is not adding, need to find prev Saturday (if it is Saturday, then it is the same day)
            while (true) {
                if (dayjs(currentDate).day() === 6) {
                    endDate = currentDate;
                    break;
                }

                currentDate = dayjs(currentDate).subtract(1, 'day');
            }
        }

        if (!startDate) {
            startDate = isAdding === true
                ? dayjs(currentDate)
                : dayjs(currentDate).subtract(weeksInDays, 'day').add(2, 'day');
        };
    
        if (!endDate) {
            endDate = isAdding 
                ? dayjs(currentDate).add(weeksInDays, 'day').subtract(2, 'day')
                : dayjs(currentDate);
        };

        if (calculateMethod.value === 'weeks') {
            // need to get a count of vacation weeks
            let count = 0;
            for (let i = 0; i < weeksInDays; i++) {
                if (dayjs(startDate).add(i, 'day').day() === 1 && isVacation(dayjs(startDate).add(i, 'day'), dayjs(startDate).add(i, 'day').add(6, 'day'))) {
                    count++;
                }
            }

            if (count > 0) {
                if (!isAdding) {
                    startDate = dayjs(startDate).subtract(count, 'week');
                    // if the newly subtracted week is a vacation week, then need to subtract one more week
                    if (isVacation(dayjs(startDate), dayjs(startDate).add(6, 'day'))) {
                        startDate = dayjs(startDate).subtract(1, 'week');

                    }
                } else {
                    endDate = dayjs(endDate).add(count, 'week');
                    // if the newly added week is a vacation week, then need to add one more week
                    if (isVacation(dayjs(endDate).subtract(6, 'day'), dayjs(endDate))) {
                        endDate = dayjs(endDate).add(1, 'week');
                    }
                }
            }
        };
    
        return { startDate, endDate };
    };


    const handleCalculateMethods = (option) => {
        if (option === 'weeks') {
            if (calculatorInput.weeks === '') {
                setErrorAlertMessage('Please enter the number of weeks');
                setErrorAlertOpen(true);
                return;
            }
            handleMiniCalendarOpen(calculatorInput.weeks, value.value);
        } else if (option === 'dates') {
            if (!calculatorInput.date) {
                setErrorAlertMessage('Please select a date');
                setErrorAlertOpen(true);
                return;
            }
            const diffInWeeks = dayjs(calculatorInput.date).diff(pickedDate, 'week');
            const isAdding = diffInWeeks > 0; // Positive means adding, negative means subtracting
            handleMiniCalendarOpen(Math.abs(diffInWeeks), isAdding);
        } else {
            if (!calculatorInput.event) {
                setErrorAlertMessage('Please select an event');
                setErrorAlertOpen(true);
                return;
            }
            const eventDate = dayjs(calculatorInput.event.date);
            const diffInWeeks = eventDate.diff(pickedDate, 'week');
            const isAdding = diffInWeeks > 0;
            handleMiniCalendarOpen(Math.abs(diffInWeeks), isAdding);
        }
    };    

    const handleMiniCalendarOpen = (weeks, isAdding) => {

        const currentDate = pickedDate;
        const { startDate, endDate } = calculateDateRange(currentDate, weeks, isAdding);
        setDateRange({ startDate, endDate });
        setOpenMiniCalendarModal(true);
    };

    const handleMiniCalendarClose = () => {
        // Reset the calculator input
        setCalculatorInput({
            weeks: '',
            date: null,
            event: null,
            eventId: '',
        });
        setOpenMiniCalendarModal(false);
    };
    
    const [eventDetails, setEventDetails] = React.useState({
        id: '',
        name: '',
        date: '',
        color: '#000000',
    });

    // Update event details when selectedEvent changes
    React.useEffect(() => {
        if (selectedEvent) {
            setEventDetails({
                id: selectedEvent.id,
                name: selectedEvent.name,
                date: selectedEvent.date,
                color: selectedEvent.color,
            });
        }
    }, [selectedEvent]);

    // Handle color change for text
    const handleColorChange = (color) => {
        setEventDetails({ ...eventDetails, color });
    };
    
    const handleClosePopover = () => {
        setAnchorEl(null);
        setUpdateAnchorEl(null);
        setCalculateOptionAnchorEl(null);
    };

    const handleModalOpen = () => {
        if (selectedEvent) {
            setEventDetails({
                id: selectedEvent.id,
                name: selectedEvent.name,
                date: selectedEvent.date,
                color: selectedEvent.color,
            });
        } else {
            setEventDetails({
                id: '',
                name: '',
                date: '',
                color: '#000000',
            });
        }
        setOpenModal(true);
        handleClosePopover();
    };

    const handleModalClose = () => {
        setOpenModal(false);
        handleClosePopover();
    };

    const handleCalculatorOpen = () => {
        setOpenCalculatorModal(true);
        handleClosePopover();
    };

    const handleCalculatorClose = () => {
        setOpenCalculatorModal(false);
        handleClosePopover();
        setCalculateOptionAnchorEl(null);
    };

    const handleEventSubmit = async () => {
        if (selectedEvent) {
            // Update existing event
            await Axios.put(`${process.env.REACT_APP_URL}/api/v1/calendar-events/${selectedEvent.id}`, {
                name: eventDetails.name,
                date: eventDetails.date,
                color: eventDetails.color,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(() => {
                setSuccessAlertMessage('Event updated successfully!');
                setSuccessAlertOpen(true);
                setUpdated(true);
                handleModalClose();
            }).catch((error) => {
                console.error(error);
                setErrorAlertMessage('Error occurred while adding event!');
                setErrorAlertOpen(true);
            });
        } else {
            // Add new event
            await Axios.post(`${process.env.REACT_APP_URL}/api/v1/calendar-events`, {
                name: eventDetails.name,
                date: dayjs(`${year}-${month + 1}-${activeDay}`, 'YYYY-M-D').tz('America/New_York').format(),
                color: eventDetails.color,
            }, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then(() => {
                setSuccessAlertMessage('Event added successfully!');
                setSuccessAlertOpen(true);
                setUpdated(true);
                handleModalClose();
            }).catch((error) => {
                console.error(error);
                setErrorAlertMessage('Error occurred while adding event!');
                setErrorAlertOpen(true);
            });
        }
    };

    const deleteEvent = () => {
        Axios.delete(`${process.env.REACT_APP_URL}/api/v1/calendar-events/${selectedEvent.id}`, {
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            setSuccessAlertMessage('Event deleted successfully!');
            setSuccessAlertOpen(true);
            setUpdated(true);
            handleClosePopover();
        }).catch((error) => {
            setErrorAlertMessage('Error occurred while deleting event!');
            setErrorAlertOpen(true);
        });
    };

    return (
        <>
        {/* Popover for adding event */}
        <Popover
            id="add-event-popover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Button onClick={handleModalOpen}>Add Event</Button>
                <Button onClick={handleCalculatorOpen}>Weeks Calculator</Button>
            </Box>
        </Popover>

        {/* Popover for updating/deleting event */}
        <Popover
            id="update-event-popover"
            open={Boolean(updateAnchorEl)}
            anchorEl={updateAnchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Button onClick={handleModalOpen}>Update Event</Button>
                <Button onClick={() => setAreYouSureOpen(true)}>Delete Event</Button>
                <Button onClick={handleCalculatorOpen}>Weeks Calculator</Button>
          </Box>
        </Popover>

        {/* Popover for calculate options */}
        <Popover
            id={calculateOptionsId}
            open={openCalculateOptions}
            anchorEl={calculateOptionAnchorEl}
            onClose={handleCloseCalculateOptions}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                {calculateMethodOptions.map((option) => (
                    <Button 
                        key={option.value} 
                        onClick={() => {
                            setCalculateMethod(option);
                            handleCloseCalculateOptions();
                        }}
                    >
                        {option.label}
                    </Button>
                ))}
            </Box>
        </Popover>

        {/* Modal for event details */}
        <Modal open={openModal} onClose={handleModalClose}>
            <Box
                sx={{
                    position: 'absolute', top: '50%', left: '50%',
                    transform: 'translate(-50%, -50%)', width: 400,
                    bgcolor: 'background.paper', p: 4, boxShadow: 24, borderRadius: 2,
                }}
            >
                <Typography variant="h6">
                    {selectedEvent ? "Update Event" : "Add Event"} 
                </Typography>
                <TextField
                    label="Event Title"
                    fullWidth
                    margin="normal"
                    value={eventDetails.name}
                    onChange={(e) => setEventDetails({ ...eventDetails, name: e.target.value })}
                />
                {/* Date Picker */}
                { selectedEvent && (       
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            timezone='America/New_York'
                            label="Date"
                            sx={{ width: "100%", mt: '16px', mb: '8px' }}
                            value={eventDetails.date ? dayjs(eventDetails.date) : null}
                            onChange={(newValue) => setEventDetails({ ...eventDetails, date: newValue })}
                        />
                    </LocalizationProvider>
                )}

                {/* Text Color Picker */}
                <ColorPicker
                    selectedColor={eventDetails.color}
                    onColorSelect={handleColorChange}
                />
                <Button variant="contained" onClick={handleEventSubmit} sx={{ mt: 2 }}>
                    {selectedEvent ? "Update" : "Save"} Event
                </Button>
            </Box>
        </Modal>

         {/* Modal for date calculator */}
        <Modal open={openCalculatorModal} onClose={handleCalculatorClose}>
            <Box
                sx={{
                    position: 'absolute', top: '50%', left: '50%',
                    transform: 'translate(-50%, -50%)', width: 400,
                    bgcolor: 'background.paper', p: 4, boxShadow: 24, borderRadius: 2,
                }}
            >
                {selectedEvent ? (
                    <Typography variant="h6">
                        Picked Date: {dayjs(selectedEvent.date).format('L')}
                    </Typography>
                ) : (
                    <Typography variant="h6">
                        Picked Date: {month + 1}/{activeDay}/{year}
                    </Typography>
                )}
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 1, mb: 1 }}>
                    
                    {/* Three Options for calculation */}
                    {calculateMethod.value === calculateMethodOptions[0].value ? (
                        <>
                        <TextField
                            sx={{ width: '35%' }}
                            label="Weeks"
                            type='number'
                            value={calculatorInput.weeks}
                            onChange={(e) => setCalculatorInput({ ...calculatorInput, weeks: e.target.value })}
                            inputProps={{ min: 1 }}
                        />
                        <Autocomplete
                            disablePortal
                            options={options}
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            sx={{ width: '60%' }}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderInput={(params) => <TextField {...params} label="Add/Subtract" />}
                        />
                        </>
                    ) : calculateMethod.value === calculateMethodOptions[1].value ? (
                        <>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                fullWidth
                                label="Dates"
                                value={calculatorInput.date ? dayjs(calculatorInput.date) : dayjs()}
                                onChange={(newValue) => setCalculatorInput({ ...calculatorInput, date: newValue })}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        </>
                    ) : (
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Events</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={calculatorInput.eventId || ''}
                                onChange={(e) => {
                                    const selectedEvent = events.find(ev => ev.id === e.target.value);
                                    setCalculatorInput({
                                        ...calculatorInput,
                                        event: selectedEvent, 
                                        eventId: e.target.value
                                    });
                                }}
                                label="Events"
                            >
                                {eventsInfo.map((event) => (
                                    <MenuItem key={event.value.id} value={event.value.id}>
                                        {event.name || 'No Event Name'}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}                   
                </Box>
                <Button variant="contained" onClick={handleCalculateOptionsClick} sx={{ mt: 2, width: '100%' }}>
                    Options
                </Button>
                {/* <Button variant="contained" onClick={() => handleMiniCalendarOpen(calculatorInput.weeks, value.value)} sx={{ mt: 2, width: '100%' }}>
                    Calculate
                </Button> */}
                <Button
                    variant="contained"
                    onClick={() => handleCalculateMethods(calculateMethod.value)} // Pass a function reference
                    sx={{ mt: 2, width: '100%' }}
                >
                    Calculate
                </Button>
            </Box>
        </Modal>

        {/* Delete Event Modal */}
        <AreYouSureModal
            colors={colors}
            title="Delete Event"
            message="Are you sure you want to delete this event?"
            onConfirm={deleteEvent}
            setOpen={setAreYouSureOpen}
            open={areYouSureOpen}
        />

        {/* Mini Calendar Modal after calculate */}
        <MiniCalendarsModal
            vacations={vacations}
            open={openMiniCalendarModal}
            onClose={handleMiniCalendarClose}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            year={year}
            miniMonth={month}
            activeDay={selectedEvent ? dayjs(selectedEvent.date).date() : activeDay}
            calculatorInput={calculatorInput}
        />
        </>
    );
};

export default CalendarEventPopup;