import React from 'react';
import Popover from '@mui/material/Popover';
import { Button, Box, IconButton } from '@mui/material';
import Axios from 'axios';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import AttendanceButtonModal from './AttendanceButtonModal';
import Checkbox from '@mui/material/Checkbox';
import SendMessageButtonModal from './SendMessageButtonModal';
import MailIcon from '@mui/icons-material/Mail';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import MemoButtonModal from './MemoButtonModal';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import { styled } from '@mui/material/styles';
// import PublicIcon from '@mui/icons-material/Public';
// import PublicOffIcon from '@mui/icons-material/PublicOff';
import SavingsIcon from '@mui/icons-material/Savings';

const StudentButtonMenu = ({
    admin_name,
    userId,
    zoom_id,
    date,
    _time,
    event_idx,
    event,
    attendance,
    enqueueSnackbar,
    colors,
    socketConnected,
    socket,
    setStudentForMoreInfo,
    setBackdropOpen,
    handleBackdropClose,
    setCheckedAttendanceList,
    checkedAttendanceList,
    attendance_len,
    showCheckBox,
    mobileMessageTemplates,
    notesMap,
    setNotesModalOpen,
    setNotesModalData,
    setDataForRequest,
}) => {
    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }
    
    const [anchorEl, setAnchorEl] = React.useState(null);

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
        )
    )(
        ({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: colors.grey[900],
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: theme.shadows[5],
                fontSize: 14,
                border: `1px solid ${colors.grey[700]}`,
            },
        })
    );

    const handleButtonClick = (e) => {
        setAnchorEl(e.currentTarget);

        if (!socketConnected) {
            enqueueSnackbar("Error: Connection to server lost. Please refresh the page.", { variant: "error" });
            return;
        }

        // need to emit to server to let other users know that this user is viewing the course
        socket.emit("put.activeUsers", {
            admin_uuid: userId,
            zoom_id: zoom_id,
            date: date,
            _time: _time,
            event_idx: event_idx,
            active: true,
        });
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);

        if (!socketConnected) {
            enqueueSnackbar("Error: Connection to server lost. Please refresh the page.", { variant: "error" });
            return;
        }

        // need to emit to server to let other users know that this user is no longer viewing the course
        socket.emit("put.activeUsers", {
            admin_uuid: userId,
            zoom_id: zoom_id,
            date: date,
            _time: _time,
            event_idx: event_idx,
            active: false,
        });
    };

    const popoverOpen = Boolean(anchorEl);
    const popoverId = popoverOpen ? 'popover' : undefined;

    async function handleMoreInfoClick() {
        setBackdropOpen(true);
        try {
            const res = await Axios.get(`${process.env.REACT_APP_URL}/api/v1/students/${attendance.student.uuid}`, {
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            });
            setStudentForMoreInfo(res.data);
        } catch (err) {
            if (err.response.status === 401) {
                handleLogout();
            } else {
                console.error(err);
                enqueueSnackbar("Error: Cannot get student information at the moment.", { variant: "error" });
            }
        }
        handleBackdropClose();
    }

    function getFontSize(start_end_diff) {
        if (!start_end_diff || start_end_diff === "1.00") {
            return "10px";
        } else {
            return "12px";
        }
    }

    function getStudentFontColor(attendance) {
        if (event.is_canceled_int || attendance.status === 4) {
            return "red !important";
        }
        if (attendance.status === 2 || attendance.status === 5) {
            return"#00B050 !important";
        }
        if (attendance.status !== 3 && attendance.session_number === 1) {
            return colors.blueAccent[600] + " !important";
        }

        return null;
    }

    function getStudentName() {
        let student_name = "";

        if (!attendance.student) {
            student_name += "Name Unknown";
        } else {
            if (attendance.student.first_name)
                student_name += attendance.student.first_name;
            if (attendance.student.korean_name)
                student_name += " " + attendance.student.korean_name;
            if (attendance.student.last_name)
                student_name += " " + attendance.student.last_name;
        }

        student_name += " ";

        if (attendance.student.grade) {
            student_name += "(" + attendance.student.grade + ") ";
        }

        if (attendance.session_number !== undefined && attendance.session_number !== null) {
            student_name += "(" + attendance.session_number;
        }

        if (attendance.student_course && attendance.student_course.total_session_num) {
            student_name += "/" + attendance.student_course.total_session_num + ")";
        } else {
            student_name += ")";
        }

        if (attendance.student?.consultation_number) {
            student_name += " (" + attendance.student.consultation_number + ")";
        }

        return student_name;
    }

    // only first, korean, last name and consultation number
    function getStudentName2() {
        let student_name = "";

        if (!attendance.student) {
            student_name += "Name Unknown";
        } else {
            if (attendance.student.first_name)
                student_name += attendance.student.first_name;
            if (attendance.student.korean_name)
                student_name += " " + attendance.student.korean_name;
            if (attendance.student.last_name)
                student_name += " " + attendance.student.last_name;
        }

        if (attendance.student?.consultation_number) {
            student_name += " (" + attendance.student.consultation_number + ")";
        }

        return student_name;
    }

    const hasNote = () => {
        if (!notesMap || !attendance || !event) {
            return false;
        }

        if (notesMap[attendance.student_id] && notesMap[attendance.student_id][event.course_id] && notesMap[attendance.student_id][event.course_id][attendance.id]) {
            return true;
        }

        return false;
    }

    const handleClickIsPaid = async (e) => {
        e.stopPropagation();

        // only vice president, manager, and developers can change the is_paid status
        if (admin_name !== "Sungha Hong" && admin_name !== "Amy An" && admin_name !== "Gyuseok Park" && admin_name !== "Keunwoo Song" && admin_name !== "Brian Lee") {
            enqueueSnackbar("Now Allowed.", { variant: "error" });
            return;
        }

        let is_paid = attendance.student_course.is_paid; // current is_paid value
        if (is_paid === 0) {
            is_paid = 1; // change to partially paid
        } else if (is_paid === 1) {
            is_paid = 2; // change to fully paid
        } else if (is_paid === 2) {
            is_paid = 0; // change to not paid
        } else {
            is_paid = 0; // change to not paid
        }
        

        try {
            // emit to server
            socket.emit("put.IsPaidUpdate", {
                student_course_id: attendance.student_course.id,
                is_paid: is_paid,
            });
        } catch (error) {
            console.error(error);
            enqueueSnackbar("Error: Cannot save the changes. Please try again.", { variant: "error" });
            return;
        }
    };

    // const addressCountryInfoOmitted = (address) => {
    //     if (!address || !address.country) {
    //         return true;
    //     }

    //     const country = address.country.toLowerCase().trim();

    //     if (country === "") {
    //         return true;
    //     }

    //     return false;
    // };

    // const doesStudentLiveOutsideUS = (address) => {
    //     const country = address.country.toLowerCase().trim();
    //     return country !== "united states" && country !== "us" && country !== "usa";
    // };

    return (<>
        <Box
            sx={{
                position: "absolute",
                right: "0",
                top: "0",
                display: 'flex',
                flexDirection: 'row',
                gap: '5px',
                zIndex: 1,
            }}
        >
            {/* {attendance.student && addressCountryInfoOmitted(attendance.student.address) ? (
                <Tooltip title="Country info not specified" arrow placement='bottom'>
                    <PublicOffIcon fontSize='medium' color='warning' />
                </Tooltip>
            ) : attendance.student && doesStudentLiveOutsideUS(attendance.student.address) ? (
                <Tooltip title="Student lives outside US" arrow placement='bottom'>
                    <PublicIcon fontSize='medium' color='info' />
                </Tooltip>
            ) : null} */}
            {attendance.student_course && attendance.student_course.is_paid === 0 ? (
                <IconButton sx={{ padding: 0 }} onClick={(e) => handleClickIsPaid(e)}>
                    <SavingsIcon fontSize='medium' color='error' />
                </IconButton>
            ) : attendance.student_course && attendance.student_course.is_paid === 1 ? (
                <IconButton sx={{ padding: 0 }} onClick={(e) => handleClickIsPaid(e)}>
                    <SavingsIcon fontSize='medium' color='success' />
                </IconButton>
            ) : attendance.student_course && attendance.student_course.is_paid === 2 ? (
                <IconButton sx={{ padding: 0 }} onClick={(e) => handleClickIsPaid(e)}>
                    <SavingsIcon fontSize='medium' color='info' />
                </IconButton>
            ) : null}
            {attendance.status === 3 && attendance.sent_cancel_reminder === false ? (
                <Tooltip title="Need to send cancel reminder" arrow placement='bottom'>
                    <MailIcon fontSize='medium' color='error' />
                </Tooltip>
            ) : attendance.status === 3 && attendance.sent_cancel_reminder === true ? (
                <Tooltip title="✔ Cancel reminder sent" arrow placement='bottom'>
                    <MarkEmailReadIcon fontSize='medium' color='error' />
                </Tooltip>
            ) : null}
            {attendance.status !== 3 && attendance.session_number === 1 && attendance.sent_first_class_reminder === false ? (
                <Tooltip title="Need to send first class reminder" arrow placement='bottom'>
                    <MailIcon fontSize='medium' sx={{ color: colors.blueAccent[600] }}  />
                </Tooltip>
            ) : attendance.status !== 3 && attendance.session_number === 1 && attendance.sent_first_class_reminder === true ? (
                <Tooltip title="✔ First class reminder sent" arrow placement='bottom'>
                    <MarkEmailReadIcon fontSize='medium' sx={{ color: colors.blueAccent[600] }} />
                </Tooltip>
            ) : null}
            {event.is_make_up_event && attendance.status !== 3 && attendance.sent_makeup_reminder === false ? (
                <Tooltip title="Need to send makeup class reminder" arrow placement='bottom'>
                    <MailIcon fontSize='medium' sx={{ color: colors.greenAccent[400] }} />
                </Tooltip>
            ) : event.is_make_up_event && attendance.status !== 3 && attendance.sent_makeup_reminder === true ? (
                <Tooltip title="✔ Makeup class reminder sent" arrow placement='bottom'>
                    <MarkEmailReadIcon fontSize='medium' sx={{ color: colors.greenAccent[400] }} />
                </Tooltip>
            ) : null}
            {hasNote() && (
                <IconButton
                    color='primary'
                    onClick={(e) => {
                        e.stopPropagation();
                        setNotesModalData([notesMap[attendance.student_id][event.course_id][attendance.id]]);
                        setNotesModalOpen(true);
                        setDataForRequest({
                            course_id: event.course_id,
                            student_name: getStudentName2(),
                        });
                    }}
                    sx={{
                        padding: 0,
                    }}
                >
                    <Tooltip title="Memo" placement="top" arrow>
                        <StickyNote2OutlinedIcon fontSize='medium' />
                    </Tooltip>
                </IconButton>
            )}
            {attendance.need_zoom_recording && !attendance.zoom_recording_uploaded ? (
                <Tooltip title="Need to upload zoom recording on classroom" arrow placement='top'>
                    <VideocamIcon fontSize='medium' color='success' />
                </Tooltip>
            ) : null}
            {attendance.student_course && attendance.student_course.memo && attendance.student_course.memo !== "" && (
                <LightTooltip 
                    title={
                        attendance.student_course.memo.split("\n").map((item, key) => {
                            return <span key={key}>{item}<br/></span>
                        })
                    } 
                    placement="right-start"
                    arrow
                >
                    <ModeCommentIcon fontSize='small' color='info' />
                </LightTooltip>
            )}
        </Box>
        {showCheckBox &&
            <Box
                sx={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    zIndex: 1,
                }}
            >
                <Checkbox
                    onChange={(e) => {
                        e.stopPropagation();
                        if (e.target.checked) {
                            setCheckedAttendanceList((prev) => {
                                const _attendance = { ...attendance, course_id: event.course_id };
                                return [...prev, _attendance];
                            });
                        } else {
                            const newCheckedAttendanceList = checkedAttendanceList.filter((item) => item.id !== attendance.id);
                            setCheckedAttendanceList(newCheckedAttendanceList);
                        }
                    }}
                    sx={{ 
                        '& .MuiSvgIcon-root': { 
                            fontSize: Math.max(32 / attendance_len, 20),
                        },
                    }}
                />
            </Box>
        }
        <Button
            sx={{
                fontSize: getFontSize(event.start_end_diff),
                textAlign: "center",
                width: "100%",
                height: "100%",
                padding: "0px",
                color: getStudentFontColor(attendance),
                textTransform: 'none',
            }}
            onClick={(e) => handleButtonClick(e)}
        >
            {getStudentName()}
        </Button>
        <Popover
            id={popoverId}
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopOverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    bgcolor: "background.paper",
                    width: "200px",
                    boxShadow: 4,
                }}
            >
                <Button 
                    color='primary'
                    sx={{ 
                        p: 1, 
                        width: "100%",
                        bgcolor: colors.primary[800],
                        borderRadius: "0px",
                    }}
                    onClick={handleMoreInfoClick}
                >
                    More Info
                </Button>
                <AttendanceButtonModal
                    admin_name={admin_name}
                    userId={userId}
                    zoom_id={zoom_id}
                    date={date}
                    _time={_time}
                    event_idx={event_idx}
                    event={event}
                    attendance={attendance}
                    enqueueSnackbar={enqueueSnackbar}
                    colors={colors}
                    handlePopOverClose={handlePopOverClose}
                    socket={socket}
                    socketConnected={socketConnected}
                    mobileMessageTemplates={mobileMessageTemplates}
                />
                <SendMessageButtonModal
                    admin_name={admin_name}
                    userId={userId}
                    zoom_id={zoom_id}
                    date={date}
                    _time={_time}
                    event_idx={event_idx}
                    event={event}
                    attendance={attendance}
                    enqueueSnackbar={enqueueSnackbar}
                    colors={colors}
                    handlePopOverClose={handlePopOverClose}
                    socket={socket}
                    socketConnected={socketConnected}
                    mobileMessageTemplates={mobileMessageTemplates}
                />
                <MemoButtonModal
                    admin_name={admin_name}
                    userId={userId}
                    zoom_id={zoom_id}
                    date={date}
                    _time={_time}
                    event_idx={event_idx}
                    event={event}
                    attendance={attendance}
                    enqueueSnackbar={enqueueSnackbar}
                    colors={colors}
                    handlePopOverClose={handlePopOverClose}
                    socket={socket}
                    socketConnected={socketConnected}
                />
            </Box>
        </Popover></>
    )
}
export default StudentButtonMenu;