import React, { Profiler, useCallback, memo, useState } from 'react';
import { tokens } from "../../theme";
import { Box, Typography, useTheme, Menu, MenuItem, Autocomplete, TextField, ListItemText, Chip } from '@mui/material';
import { DateTime } from 'luxon';
import { generateWeekDates, generateWeekDatesForTeacherScheduler, generateWeekDatesForAdmin } from '../../utils/weekDates';
import Button from '@mui/material/Button';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import axios from 'axios';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import Header from '../../components/Header';
import { Stack, Backdrop, Modal, Fade, Tooltip, Popover, Select, Checkbox, InputLabel, FormControl } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import IndividualSchedule from './IndividualSchedule';
import Loading from '../../components/Loading';
import Alert from '../../components/alert/Alert';

const HOURS = [
    { label: "8:00 AM", value: "08:00" },
    { label: "9:00 AM", value: "09:00" },
    { label: "10:00 AM", value: "10:00" },
    { label: "11:00 AM", value: "11:00" },
    { label: "12:00 PM", value: "12:00" },
    { label: "1:00 PM", value: "13:00" },
    { label: "2:00 PM", value: "14:00" },
    { label: "3:00 PM", value: "15:00" },
    { label: "4:00 PM", value: "16:00" },
    { label: "5:00 PM", value: "17:00" },
    { label: "6:00 PM", value: "18:00" },
    { label: "7:00 PM", value: "19:00" },
    { label: "8:00 PM", value: "20:00" },
    { label: "9:00 PM", value: "21:00" },
    { label: "10:00 PM", value: "22:00" },
    { label: "11:00 PM", value: "23:00" },
];

const DURATIONS = [
    { label: '30 minutes', value: 0.5 },
    { label: '1 hour', value: 1 },
    { label: '2 hours', value: 2 },
];

const SUBJECT_CATEGORIES = [
    "Biology",
    "Chemistry",
    "Computer Science",
    "Economics",
    "English",
    "History",
    "Math",
    "Physics",
    "Research",
    "Writing Competition",
];

const TeacherScheduleCheck = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isDarkMode = theme.palette.mode === 'dark';
    const signOut = useSignOut();
    const navigate = useNavigate();

    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    };

    const today = DateTime.now();
    const one_week_ago = today.minus({ week: 1 });
    const one_week_later = today.plus({ week: 1 });

    const [date, setDate] = useState(one_week_later);
    const [deadlineDate, setDeadlineDate] = useState(one_week_ago);
    const [duration, setDuration] = useState(0.5);
    const [weekDates, setWeekDates] = useState(generateWeekDatesForTeacherScheduler(date, duration));
    const [targetSubjects, setTargetSubjects] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedSlots, setSelectedSlots] = useState({});
    const [possibleSlots, setPossibleSlots] = useState({});
    const [startTime, setStartTime] = useState("08:00");
    const [endTime, setEndTime] = useState("00:00");
    const [modalOpen, setModalOpen] = useState(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [popoverAnchorEl, setPopoverAnchorEl] = useState({});
    const [alertState, setAlertState] = useState({
        successOpen: false,
        successMessage: "",
        errorOpen: false,
        errorMessage: ""
    });

    // Teacher Chip should only do one render
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [teacherModalOpen, setTeacherModalOpen] = useState(false);

    const weekForDeadline = generateWeekDates(deadlineDate);
    const deadline_date = weekForDeadline && weekForDeadline.length > 0
        && weekForDeadline[weekForDeadline.length - 1].date.toJSDate() < new Date('2024-10-07T23:59:59.000Z')
        && weekForDeadline[1].date.toJSDate() > new Date('2024-09-29T00:00:00.000Z')
        ? weekForDeadline[5].date.set({ hour: 23, minute: 59, second: 59 }).toFormat('ccc, DD')
        : weekForDeadline[3].date.set({ hour: 23, minute: 59, second: 59 }).toFormat('ccc, DD');

    // Handlers
    const handleDateChange = (currentDate, direction) => {
        let newDate;
        let newDeadlineDate;

        if (direction === -1) {
            newDate = one_week_later;
            newDeadlineDate = one_week_ago;
        } else if (direction === 0) {
            newDate = currentDate.minus({ days: 7 });
            newDeadlineDate = deadlineDate.minus({ days: 7 });
        } else {
            newDate = currentDate.plus({ days: 7 });
            newDeadlineDate = deadlineDate.plus({ days: 7 });
        }

        setDate(newDate);
        setDeadlineDate(newDeadlineDate);
        setWeekDates(generateWeekDatesForTeacherScheduler(newDate, duration));
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };
    const handleDurationChange = (newDuration) => {
        setDuration(newDuration);
        setWeekDates(generateWeekDatesForTeacherScheduler(date, newDuration));
        handleMenuClose();
    };

    const handleTimeRangeChange = () => {
        const start = DateTime.fromISO(`${date.toISODate()}T${startTime}`);
        const end = DateTime.fromISO(`${date.toISODate()}T${endTime}`);

        if (start >= end) {
            setAlertState(prev => ({
                ...prev,
                errorOpen: true,
                errorMessage: "Start time must be before end time."
            }));
            return;
        }

        setWeekDates(generateWeekDatesForAdmin(date, duration, startTime, endTime));
        setModalOpen(false);
    };

    const handleReset = () => {
        setDuration(0.5);
        setStartTime("08:00");
        setEndTime("00:00");
        setWeekDates(generateWeekDatesForTeacherScheduler(date, 0.5));
    };

    const handlePopoverOpen = useCallback((event, key) => {
        setPopoverAnchorEl(prev => ({
            ...prev,
            [key]: event.currentTarget
        }));
    }, []);

    const handlePopoverClose = useCallback((key) => {
        setPopoverAnchorEl(prev => ({
            ...prev,
            [key]: null
        }));
    }, []);

    // Helper functions
    const isNoClassSlot = (dateObj, hour) => {
        if (!dateObj || !dateObj.date) return false;

        const noClassStartTime = 8;
        const noClassEndTime = 15.5;
        const hourValue = DateTime.fromFormat(hour, 'h:mm a').hour +
            (DateTime.fromFormat(hour, 'h:mm a').minute === 30 ? 0.5 : 0);

        const weekday = dateObj.date.weekday;
        return weekday === 7 || (weekday >= 1 && weekday <= 5 &&
            hourValue >= noClassStartTime && hourValue <= noClassEndTime);
    };

    const findHourObject = (timeValue) => HOURS.find(hour => hour.value === timeValue);

    const popoverOpen = useCallback((key) => Boolean(popoverAnchorEl[key]), [popoverAnchorEl]);
    const popoverId = (key) => popoverOpen(key) ? `popover-${key}` : undefined;

    // Data fetching
    React.useEffect(() => {
        const fetchTeacherAvailability = async () => {
            if (weekDates.length === 0 || !weekDates[1]?.date || !weekDates[7]?.date) {
                console.error("WeekDates array is not properly populated.");
                return;
            }

            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/api/v1/teachers/availability/all`, {
                    params: {
                        week_start: weekDates[1].date.set({ hour: 0, minute: 0, second: 0 }).toISO(),
                        week_end: weekDates[7].date.set({ hour: 23, minute: 59, second: 59 }).toISO(),
                        subjects: targetSubjects,
                    },
                    headers: {
                        Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                    },
                });

                const availability = response.data;

                if (availability?.length > 0) {
                    const slotMap = {};
                    const possibleSlotMap = {};
                    let duration_count = 0;
                    const startHour = parseInt(startTime.split(":")[0]);
                    const startHourIsEven = startHour % 2 === 0;

                    for (const slot of availability) {
                        const hour = slot.date.split("T")[1].split(":")[0];
                        const minute = slot.date.split("T")[1].split(":")[1];

                        if (duration === 0.5 ||
                            (duration === 1 && minute === "00") ||
                            (duration === 2 && minute === "00" &&
                                ((startHourIsEven && hour % 2 === 0) || (!startHourIsEven && hour % 2 === 1)))) {
                            duration_count = 0;
                        }

                        let date = DateTime.fromISO(slot.date);
                        let dateIndex = weekDates.findIndex(weekDate =>
                            weekDate.date?.toISODate() === date.toISODate());
                        let slotIndex = weekDates[dateIndex]?.hours.findIndex(hour =>
                            hour === date.toFormat('h:mm a'));

                        duration_count++;

                        const key = `${dateIndex}-${slotIndex}`;
                        const teacherData = {
                            uuid: slot.teacher.uuid,
                            first_name: slot.teacher.first_name,
                            last_name: slot.teacher.last_name
                        };

                        if (duration === 0.5 ||
                            (duration === 1 && duration_count === 2) ||
                            (duration === 2 && duration_count === 4)) {

                            if (!slotMap[key]) {
                                slotMap[key] = [teacherData];
                            } else {
                                slotMap[key].push(teacherData);
                            }
                            duration_count = 0;
                        } else {
                            const possibleTeacherData = {
                                ...teacherData,
                                possible_time: date.toFormat('h:mm a')
                            };

                            if (!possibleSlotMap[key]) {
                                possibleSlotMap[key] = [possibleTeacherData];
                            } else {
                                possibleSlotMap[key].push(possibleTeacherData);
                            }
                        }
                    }

                    setSelectedSlots(slotMap);
                    setPossibleSlots(possibleSlotMap);
                } else {
                    setSelectedSlots({});
                    setPossibleSlots({});
                }
            } catch (error) {
                console.error(error);
                if (error.response?.status === 401) {
                    handleLogout();
                }
                setAlertState(prev => ({
                    ...prev,
                    errorOpen: true,
                    errorMessage: error.response?.data?.message || "An error occurred."
                }));
            } finally {
                setLoading(false);
            }
        };

        fetchTeacherAvailability();
    }, [weekDates, targetSubjects]);

    // Memoized table cell component
    const TableCell = memo(({ 
        dateIndex, 
        hourIndex, 
        hour, 
        dateObj, 
        selectedTeachers, 
        possibleTeachers,
        isDarkMode,
        duration,
        date,
        handlePopoverOpen,
        popoverOpen,
        popoverAnchorEl,
        handlePopoverClose,
    }) => {
        const key = `${dateIndex}-${hourIndex}`;
        const isNoClass = isNoClassSlot(dateObj, hour);
        
        // Memoize expensive groupedTeachers calculation
        const groupedTeachers = React.useMemo(() => {
            const isPossible = possibleTeachers.filter(teacher =>
                !selectedTeachers.find(t => t.uuid === teacher.uuid)
            );
            
            return isPossible.reduce((acc, teacher) => {
                if (!acc[teacher.uuid]) {
                    acc[teacher.uuid] = {
                        first_name: teacher.first_name,
                        last_name: teacher.last_name,
                        uuid: teacher.uuid,
                        times: [teacher.possible_time],
                    };
                } else {
                    acc[teacher.uuid].times.push(teacher.possible_time);
                }
                return acc;
            }, {});
        }, [possibleTeachers, selectedTeachers]);

        return (
            <td
                style={{
                    borderLeft: dateIndex === 1 ? "1px solid lightgrey" : undefined,
                    borderRight: "1px solid lightgrey",
                    borderBottom: "1px solid lightgrey",
                    padding: '8px',
                    backgroundColor: hourIndex % 2 === 0
                        ? (isDarkMode ? '#121212' : 'Azure')
                        : (isDarkMode ? '#373737' : 'Beige'),
                    verticalAlign: 'top',
                    textAlign: 'center',
                }}
            >
                {dateIndex === 0 && (
                    <Tooltip title={`${hour} ~ ${DateTime.fromFormat(hour, 'h:mm a').plus({ minutes: duration * 60 }).toFormat('h:mm a')}`} placement="right">
                        <Typography variant="h6">{hour}</Typography>
                    </Tooltip>
                )}

                {selectedTeachers.length > 0 ? (
                    <Stack spacing={1}>
                        {selectedTeachers.map((teacher, idx) => (
                            <TeacherChip
                                key={idx}
                                teacher={teacher}
                                time={date}
                                isDarkMode={isDarkMode}
                            />
                        ))}
                    </Stack>
                ) : (
                    <Typography variant="body2">
                        {isNoClass ? 'No Class' : null}
                    </Typography>
                )}

                {Object.keys(groupedTeachers).length > 0 && (
                    <div>
                        <Button
                            aria-describedby={popoverId(key)}
                            variant="outlined"
                            size="small"
                            onClick={(e) => handlePopoverOpen(e, key)}
                            sx={{
                                textTransform: 'none',
                                padding: '4px 8px',
                                fontSize: '12px',
                                mt: 1,
                            }}
                        >
                            See Possible Teachers
                        </Button>

                        <Popover
                            id={popoverId(key)}
                            open={popoverOpen(key)}
                            anchorEl={popoverAnchorEl[key]}
                            onClose={() => handlePopoverClose(key)}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <Box sx={{ p: 2, maxWidth: 400 }}>
                                {Object.values(groupedTeachers).map((teacher, teacherIdx) => (
                                    <Box key={teacherIdx} sx={{ mb: 2 }}>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: isDarkMode ? 'white' : 'black' }}>
                                            {`${teacher.first_name} ${teacher.last_name}`}
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                                            {teacher.times.map((time, timeIdx) => {
                                                const startTime = DateTime.fromFormat(time, 'h:mm a');
                                                const endTime = startTime.plus({ minutes: 30 });
                                                return (
                                                    <Typography key={timeIdx} variant="body2" sx={{ color: isDarkMode ? 'white' : 'gray' }}>
                                                        {`${startTime.toFormat('h:mm a')} ~ ${endTime.toFormat('h:mm a')}`}
                                                    </Typography>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Popover>
                    </div>
                )}
            </td>
        );
    });

    // Memoized TeacherChip component
    const TeacherChip = memo(({ teacher, time, isDarkMode }) => {
        const handleClick = useCallback(() => {
            setSelectedTeacher(teacher);
            setTeacherModalOpen(true);
        }, [teacher]);
        
        return (
            <Chip
                icon={<FaceIcon />} 
                label={`${teacher.first_name} ${teacher.last_name}`}
                onClick={handleClick}
                size='small'
                sx={{
                    backgroundColor: isDarkMode ? '#28292c' : 'lightgrey',
                    zIndex: 1,
                    fontWeight: 'bold',
                    marginBottom: '4px'
                }}
            />
        );
    });

    const renderTableHeader = () => (
        <thead>
            <tr>
                {weekDates.map((dateObj, dateIndex) => (
                    <th
                        key={dateIndex}
                        style={{
                            position: 'sticky',
                            top: 0,
                            borderLeft: dateIndex === 1 ? "1px solid lightgrey" : undefined,
                            borderRight: "1px solid lightgrey",
                            borderBottom: "1px solid lightgrey",
                            backgroundColor: dateObj.date?.toISODate() === DateTime.now().toISODate() ? 'green' : isDarkMode ? '#28292c' : 'lightgrey',
                            color: isDarkMode ? 'white' : 'black',
                            textAlign: 'center',
                            minHeight: 30,
                            padding: '8px',
                            zIndex: 2
                        }}
                    >
                        {dateIndex === 0 ? "Time" :
                            <Typography variant="h6">
                                {dateObj.date.toLocaleString({ weekday: "short" })}, {dateObj.date.toLocaleString({ month: "short" })} {dateObj.date.day}, {dateObj.date.year}
                            </Typography>
                        }
                    </th>
                ))}
            </tr>
        </thead>
    );

    const renderTableBody = useCallback(() => {
        // console.time('renderTableBody');
        const body = (
            <tbody>
                {weekDates[0].hours.map((hour, hourIndex) => {
                    console.time(`row-${hourIndex}`);
                    const row = (
                        <tr key={hourIndex}>
                            {weekDates.map((dateObj, dateIndex) => {
                                const key = `${dateIndex}-${hourIndex}`;
                                return (
                                    <TableCell
                                        key={key}
                                        dateIndex={dateIndex}
                                        hourIndex={hourIndex}
                                        hour={hour}
                                        dateObj={dateObj}
                                        selectedTeachers={selectedSlots[key] || []}
                                        possibleTeachers={possibleSlots[key] || []}
                                        isDarkMode={isDarkMode}
                                        duration={duration}
                                        date={date}
                                        handlePopoverOpen={handlePopoverOpen}
                                        popoverOpen={popoverOpen}
                                        popoverAnchorEl={popoverAnchorEl}
                                        handlePopoverClose={handlePopoverClose}
                                    />
                                );
                            })}
                        </tr>
                    );
                    // console.timeEnd(`row-${hourIndex}`);
                    return row;
                })}
            </tbody>
        );
        // console.timeEnd('renderTableBody');
        return body;
    }, [weekDates, selectedSlots, possibleSlots, isDarkMode, duration, date, handlePopoverOpen, popoverOpen, popoverAnchorEl, handlePopoverClose]);

    const renderTimeModal = () => (
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
        >
            <Fade in={modalOpen}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography variant="h6">Set Time Range</Typography>
                        <Typography sx={{ fontSize: '12px' }}>
                            Current Interval: {duration === 0.5 ? "30 minutes" : duration === 1 ? "1 hour" : "2 hours"}
                        </Typography>
                    </Box>
                    <Autocomplete
                        options={HOURS}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => <TextField {...params} label="Start Time" />}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(event, value) => setStartTime(value ? value.value : "08:00")}
                        value={findHourObject(startTime)}
                        sx={{ mt: 2 }}
                    />
                    <Autocomplete
                        options={HOURS}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => <TextField {...params} label="End Time" />}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(event, value) => setEndTime(value ? value.value : "00:00")}
                        value={findHourObject(endTime)}
                        sx={{ mt: 2 }}
                    />
                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                        <Button variant="contained" color="primary" onClick={handleTimeRangeChange}>
                            Save
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );

    return (
        // <Profiler id="TeacherScheduleCheck" onRender={(id, phase, actualDuration) => {
        //     if (actualDuration > 16) {
        //         console.log(`[${id}] ${phase} took ${actualDuration}ms`);
        //     }
        // }}>
            <Box m="20px" marginX={3} marginY={3}>
                <Header title="Weekly Availability" subtitle="A ONE Institute" />

                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mr: 1 }}>
                    <Box sx={{ display: "flex", gap: 2 }}>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                            sx={{ '&:hover': { backgroundColor: colors.grey[700] } }}
                        >
                            Time Interval
                        </Button>
                        <Menu
                            open={Boolean(menuAnchorEl)}
                            onClose={() => setMenuAnchorEl(null)}
                            anchorEl={menuAnchorEl}
                        >
                            {DURATIONS.map((d, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={() => handleDurationChange(d.value)}
                                >
                                    {d.label}
                                </MenuItem>
                            ))}
                        </Menu>

                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => setModalOpen(true)}
                            sx={{ '&:hover': { backgroundColor: colors.grey[700] } }}
                        >
                            <AccessTimeFilledIcon sx={{ mr: 1 }} />
                            Range
                        </Button>

                        <Button
                            variant="contained"
                            size="small"
                            onClick={handleReset}
                            sx={{ '&:hover': { backgroundColor: colors.grey[700] } }}
                        >
                            <RestartAltIcon sx={{ mr: 1 }} />
                            Reset Setting
                        </Button>

                        <FormControl sx={{ minWidth: '100px' }}>
                            <InputLabel>Subjects</InputLabel>
                            <Select
                                multiple
                                value={targetSubjects}
                                onChange={(event) => setTargetSubjects(
                                    typeof event.target.value === 'string'
                                        ? event.target.value.split(',')
                                        : event.target.value
                                )}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {SUBJECT_CATEGORIES.map((subject, index) => (
                                    <MenuItem key={index} value={subject}>
                                        <Checkbox checked={targetSubjects.indexOf(subject) > -1} />
                                        <ListItemText primary={subject} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => handleDateChange(date, -1)}
                            sx={{ '&:hover': { backgroundColor: colors.grey[700] } }}
                        >
                            First Page
                        </Button>

                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => handleDateChange(date, 0)}
                            sx={{ '&:hover': { backgroundColor: colors.grey[700] } }}
                        >
                            <KeyboardArrowLeftIcon />
                        </Button>

                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => handleDateChange(date, 1)}
                            sx={{ '&:hover': { backgroundColor: colors.grey[700] } }}
                        >
                            <KeyboardArrowRightIcon />
                        </Button>
                    </Box>

                    <Box>
                        <Typography variant="h4" sx={{ display: "flex", alignItems: "center", color: "red" }}>
                            Deadline for this week: {deadline_date} (EDT)
                        </Typography>
                    </Box>
                </Box>

                {loading ? <Loading /> : (
                    <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '14px', marginTop: 10 }}>
                        {renderTableHeader()}
                        {renderTableBody()}
                    </table>
                )}

                {renderTimeModal()}

                <Alert
                    successAlertOpen={alertState.successOpen}
                    setSuccessAlertOpen={(open) => setAlertState(prev => ({ ...prev, successOpen: open }))}
                    errorAlertOpen={alertState.errorOpen}
                    setErrorAlertOpen={(open) => setAlertState(prev => ({ ...prev, errorOpen: open }))}
                    successMsg={alertState.successMessage}
                    errorMsg={alertState.errorMessage}
                    vertical="bottom"
                    horizontal="left"
                />

                {selectedTeacher && (
                    <IndividualSchedule 
                        userId={selectedTeacher.uuid} 
                        teacher={selectedTeacher} 
                        open={teacherModalOpen} 
                        handleClose={() => {
                            setTeacherModalOpen(false);
                            setSelectedTeacher(null);
                        }} 
                        time={date} 
                        isDarkMode={isDarkMode}
                    />
                )}
            </Box>
        // </Profiler>
    );
};

export default TeacherScheduleCheck;