import React from 'react';
import Header from '../../components/Header';
import { Box, Button } from "@mui/material";
import Loading from '../../components/Loading';
import Axios from 'axios';
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { Editor } from '@tinymce/tinymce-react';

const VICE_PRESIDENT_MEMO_ID = 1;

const options = {
    height: 800,
    menubar: false,
    plugins: [
        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
        'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
    ],
    toolbar: [
        ' undo redo | blocks | bold italic forecolor backcolor fontsize | fontselect |' +
        'alignleft aligncenter alignright alignjustify | lineheight |' +
        'bullist numlist outdent indent | removeformat | help'
    ],
    font_formats: 'Arial=arial,helvetica,sans-serif;' +
                 'Arial Black=arial black,avant garde;' +
                 'Courier New=courier new,courier;' +
                 'Georgia=georgia,palatino;' +
                 'Tahoma=tahoma,arial,helvetica,sans-serif;' +
                 'Times New Roman=times new roman,times;' +
                 'Verdana=verdana,geneva;' +
                 'Impact=impact,chicago;' +
                 'Comic Sans MS=comic sans ms,sans-serif;' +
                 'Trebuchet MS=trebuchet ms,geneva;',
    htmlAllowedTags:  ['.*'],
    htmlAllowedAttrs: ['.*'],
    extended_valid_elements: '*[.*]',
    content_style: "p { margin: 0; }",
};

function VicePresidentMemos() {

    // for 401 error
    const signOut = useSignOut();
    const navigate = useNavigate();
    const handleLogout = () => {
        signOut();
        localStorage.removeItem("userName");
        localStorage.removeItem("who");
        navigate("/signin");
    }    

    const [loading, setLoading] = React.useState(false);
    const [is_editing, setIsEditing] = React.useState(false);

    const [prevMemo, setPrevMemo] = React.useState(null);
    const [memo, setMemo] = React.useState(null);

    React.useEffect(() => {
        async function fetchMemo() {
            setLoading(true);
            await Axios.get(`${process.env.REACT_APP_URL}/api/v1/vice-president-memos/${VICE_PRESIDENT_MEMO_ID}`, {
                timeout: 10000,
                headers: {
                    Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
                },
            }).then((response) => {
                setMemo(response.data.memo);
                setPrevMemo(response.data.memo);
                setLoading(false);
            }).catch((error) => {
                if (error.response !== null && error.response.status === 401) {
                    alert(error.response.data.error || error.response.data.message);
                    handleLogout();
                    return;
                } else {
                    alert(error.response.data.error || error.response.data.message);
                    setLoading(false);
                    return;
                }
            });
        }

        fetchMemo();
    }, []);

    async function saveMemo() {
        if (prevMemo === memo) {
            setIsEditing(false);
            return;
        }

        await Axios.put(`${process.env.REACT_APP_URL}/api/v1/vice-president-memos/${VICE_PRESIDENT_MEMO_ID}`, {
            memo: memo,
        }, {
            timeout: 10000,
            headers: {
                Authorization: `Bearer ${document.cookie?.split("=")[1].split(";")[0]}`,
            },
        }).then((response) => {
            alert(response.data.message);
            setPrevMemo(memo);
            setIsEditing(false);
        }).catch((error) => {
            if (error.response !== null && error.response.status === 401) {
                alert(error.response.data.error || error.response.data.message);
                setIsEditing(false);
                handleLogout();
                return;
            } else {
                alert(error.response.data.error || error.response.data.message);
                setIsEditing(false);
                return;
            }
        });
    }

    return (
        <Box m="20px" marginX={3} marginY={3} height="89vh">
            { loading ? 
                <Loading />
                : 
                <Box 
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Header title="Vice President Memo" subtitle="A ONE Institute" />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            height: "100%",
                            marginBottom: "40px",
                        }}
                    >
                        {is_editing ? (
                            <>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={saveMemo}
                                    sx={{
                                        marginBottom: "20px",
                                        maxWidth: "100px",
                                    }}
                                >
                                    Save
                                </Button>
                                <Editor
                                    apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                                    init={options}
                                    value={memo}
                                    onEditorChange={
                                        (newValue, editor) => {
                                            setMemo(newValue);
                                        }
                                    }
                                />
                            </>
                        ) : (
                            <>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setIsEditing(true)}
                                    sx={{
                                        marginBottom: "20px",
                                        maxWidth: "100px",
                                    }}
                                >
                                    Edit
                                </Button>
                                <div 
                                    dangerouslySetInnerHTML={{ __html: memo }} 
                                    style={{
                                        minHeight: 800,
                                        width: "100%",
                                        padding: "10px 20px",
                                        backgroundColor: "#fff",
                                    }}
                                />
                            </>
                        )}
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default VicePresidentMemos